/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */

html,
button,
input,
select,
textarea {
    color: #222;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

a:active {
    color: #333;
}

a,
a:focus {
    outline: none;
}

body {
    font-size: 1em;
    line-height: 1.4;
}

::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}

::selection {
    background: #b3d4fc;
    text-shadow: none;
}

::-webkit-input-placeholder {
    color: #CCC;
    opacity: 1 !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #CCC;
    opacity: 1 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #CCC;
    opacity: 1 !important;
}

:-ms-input-placeholder {
    color: #CCC;
    opacity: 1 !important;
}

/* ==========================================================================
   Custom styles
   ========================================================================== */

html,
body {
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: grayscale;
}

html * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* http://stackoverflow.com/questions/9009377/ios-dark-gray-box-active-style */

@import "utils.less";
@import "components.less";
/*** DIV con background come sfondo ***/

.bg-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

/*** Variabili ***/

@nero: #000000;
@bianco: #ffffff;
@oro: #ebcd65;
@oroLight: #7d6425;
@magenta: #a22b2b;
@viola: #85365e;
@oroMedio: #d49a0a;
@oroScuro: #a3780f;
@magentaScuro: #6c0303;
@violaScuro: #5c2541;
@hoverOro: #bb8c0d;
@hoverMagenta: #8e0017;
@hoverViola: #7b0146;
@grigio: #dcdcdc;
@grigioScuro: #cbcbcb;
@testo: #333333;
@ral: 'Raleway',
sans-serif;
body {
    font-family: @ral;
    font-size: 16px;
    .oh;
    height: 100vh;
}

body.home {
    overflow: visible;
    height: auto;
}

.age-consent-overlayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("@{imgs}bg-black-90.png");
    z-index: 1000;
    .content {
        max-width: 390px;
        padding: 0 10px;
        margin: 0 auto;
        text-transform: uppercase;
        text-align: center;
        color: @bianco;
        .button {
            color: @bianco;
            padding: 10px 30px;
            margin: 0 10px;
            border: 3px solid @bianco;
            webkit-transition: all 0.7s ease-in-out;
            -moz-transition: all 0.7s ease-in-out;
            -o-transition: all 0.7s ease-in-out;
            transition: all 0.7s ease-in-out;
            &:hover {
                background: @bianco;
                color: @nero;
            }
        }
    }
    .brand,
    h2,
    p {
        margin: 30px 0;
        img {
            height: auto !important;
            max-width: 100%;
        }
    }
    p {
        font-size: 0.85em;
    }
}

.video-overlayer {
    position: fixed;
    .dn;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("@{imgs}bg-black-90.png");
    z-index: 998;
    .content {
        .pr;
        width: 100%;
        height: 100vh;
        margin: 0 auto;
        text-transform: uppercase;
        text-align: center;
        color: @bianco;
        .buttons-wrapper {
            .pa;
            bottom: 50px;
            .fl;
            width: 100%;
            .tac;
            margin-top: 25px;
            z-index: 999;
            .button {
                color: @bianco;
                padding: 10px 30px;
                margin: 0 10px;
                border: 3px solid @bianco;
                webkit-transition: all 0.7s ease-in-out;
                -moz-transition: all 0.7s ease-in-out;
                -o-transition: all 0.7s ease-in-out;
                transition: all 0.7s ease-in-out;
                &:hover {
                    background: @bianco;
                    color: @nero;
                }
            }
        }
        iframe {
            .pr;
            width: 100%;
            height: 100vh;
            z-index: 998;
            margin-bottom: 20px;
        }
    }
}

@media (min-width: 1920px) {
    body {
        font-family: @ral;
        font-size: 20px;
    }
}

#top {
    .fl;
    .pr;
    width: 100%;
    height: 100vh;
    .topBar {
        .fl;
        .tac;
        .pa;
        top: 0;
        z-index: 10;
        padding: 20px 2%;
        height: 82px;
        .burgerMenu {
            .fl;
            background: url("@{imgs}ico-menu.png") left center repeat-x;
            width: 15px;
            height: 35px;
            cursor: pointer;
            margin-top: 20px;
            &:hover {
                -webkit-transition: width 0.2s ease-in-out;
                -moz-transition: width 0.2s ease-in-out;
                -o-transition: width 0.2s ease-in-out;
                transition: width 0.2s ease-in-out;
                width: 35px;
            }
        }
        .logo {
            .dn;
            .pa;
            top: 10px;
            right: 10%;
            width: 80%;
        }
    }
    &.closed {
        //background: url("@{imgs}bg-header-closed.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right center;
        .mainContent {
            .pa;
            .fl;
            width: 100%;
            height: 100%;
            .oh;
            .col75 {
                .fl;
                .pr;
                width: 67%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                h1 {
                    font-size: 0;
                }
                .logo {
                    .pa;
                    .fl;
                    .tac;
                    width: 100%;
                    transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -webkit-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    top: 50%;
                    img {
                        max-width: 375px;
                    }
                }
                .icoScroll {
                    .pa;
                    background: url("@{imgs}ico-scroll.png") center top no-repeat;
                    background-size: 100% auto;
                    width: 30px;
                    height: 100px;
                    z-index: 10;
                    bottom: 20px;
                    left: calc(~"50% - 15px");
                    cursor: pointer;
                }
            }
            .col25 {
                .pr;
                .fl;
                width: 33%;
                height: 100%;
                .boxLink {
                    .pr;
                    .fl;
                    width: 100%;
                    height: 33.3%;
                    cursor: pointer;
                    .layerBg {
                        .pa;
                        .fl;
                        width: 100%;
                        height: 100%;
                        opacity: 0.87;
                    }
                    h2 {
                        .pa;
                        .fl;
                        .tac;
                        width: 100%;
                        transform: translateY(-70%);
                        -moz-transform: translateY(-70%);
                        -webkit-transform: translateY(-70%);
                        -o-transform: translateY(-70%);
                        top: 50%;
                        font-size: 0.875em;
                        letter-spacing: 2.2px;
                        a {
                            .fl;
                            .tac;
                            .tuc;
                            width: 100%;
                            color: @grigio;
                            font-weight: 800;
                            text-decoration: none;
                        }
                    }
                    .overlay {
                        .dn;
                        .pa;
                        .fl;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        a {
                            .pr;
                            .fl;
                            width: 100%;
                            height: 100%;
                        }
                        .content {
                            .pa;
                            .fl;
                            .tac;
                            width: 70%;
                            padding: 0 15%;
                            transform: translateY(-50%);
                            -moz-transform: translateY(-50%);
                            -webkit-transform: translateY(-50%);
                            -o-transform: translateY(-50%);
                            top: 50%;
                            p {
                                font-size: 0.81em;
                                line-height: 1.3em;
                                color: @bianco;
                                letter-spacing: 1px;
                            }
                            .cta-txt {
                                font-size: 0.81em;
                                color: @bianco;
                                .tuc;
                                font-weight: 700;
                                letter-spacing: 2.2px;
                                span {
                                    .fl;
                                    width: 100%;
                                    .tac;
                                }
                            }
                            .cta-txt::before {
                                content: '';
                                width: 14px;
                                height: 7px;
                                background: @bianco;
                                display: inline-block;
                                margin-bottom: 10px;
                                opacity: .8;
                            }
                        }
                    }
                    &.bg-oro {
                        .layerBg {
                            background: url("@{imgs}bg-opacity-oro.png");
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                        }
                        h2::before {
                            content: '';
                            width: 14px;
                            height: 7px;
                            background: @oro;
                            display: inline-block;
                            margin-bottom: 10px;
                            opacity: .8;
                        }
                        .overlay {
                            background-color: @hoverOro;
                            .content {
                                .cta-txt::before {
                                    background: @oro;
                                }
                            }
                        }
                    }
                    &.bg-magenta {
                        .layerBg {
                            background: url("@{imgs}bg-opacity-magenta.png");
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                        }
                        h2::before {
                            content: '';
                            width: 14px;
                            height: 7px;
                            background: @magenta;
                            display: inline-block;
                            margin-bottom: 10px;
                            opacity: .8;
                        }
                        .overlay {
                            background-color: @hoverMagenta;
                            .content {
                                .cta-txt::before {
                                    background: @magenta;
                                }
                            }
                        }
                    }
                    &.bg-viola {
                        .layerBg {
                            background: url("@{imgs}bg-opacity-viola.png");
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                        }
                        h2::before {
                            content: '';
                            width: 14px;
                            height: 7px;
                            background: @viola;
                            display: inline-block;
                            margin-bottom: 10px;
                            opacity: .8;
                        }
                        .overlay {
                            background-color: @hoverViola;
                            .content {
                                .cta-txt::before {
                                    background: @viola;
                                }
                            }
                        }
                    }
                }
            }
            &.page-lvl-1 {
                .col75 {
                    overflow-x: hidden;
                    overflow-y: auto;
                    background: @bianco;
                    .headerSide {
                        .pr;
                        .fl;
                        width: 96%;
                        background: @nero;
                        padding: 20px 2%;
                        height: 82px;
                        .logoSmall {
                            .tac;
                            .fl;
                            width: 100%;
                            img {
                                height: 82px;
                                width: auto;
                            }
                        }
                        .social {
                            .pa;
                            .fr;
                            .tar;
                            top: 30px;
                            right: 2%;
                            font-weight: 600;
                            color: @bianco;
                            font-size: 0.81em;
                            span {
                                .fl;
                                margin-top: 20px;
                                margin-right: 10px;
                                opacity: 0.30;
                            }
                            a {
                                .fl;
                                margin-top: 15px;
                            }
                        }
                    }
                    .category {
                        .pr;
                        .fl;
                        width: 100%;
                        height: calc(~"100vh - 122px");
                        &.sezionePremi {
                            background: url("@{imgs}bg-premi.png") center center no-repeat @oroLight;
                        }
                        .boxSezione {
                            .pr;
                            .oh;
                            .fl;
                            width: 50%;
                            height: 50%;
                            background: @nero;
                            .imgContainer {
                                .pr;
                                .fl;
                                width: 100%;
                                height: 100%;
                                img {
                                    opacity: 0;
                                    .fl;
                                    width: 100%;
                                }
                                &.ben {
                                    opacity: 0.65;
                                    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
                                    filter: gray;
                                    -webkit-filter: grayscale(100%);
                                    -moz-filter: grayscale(100%);
                                    -ms-filter: grayscale(100%);
                                    -o-filter: grayscale(100%);
                                    filter: grayscale(100%);
                                }
                                svg {
                                    background: url(http://4.bp.blogspot.com/-IzPWLqY4gJ0/T01CPzNb1KI/AAAAAAAACgA/_8uyj68QhFE/s400/a2cf7051-5952-4b39-aca3-4481976cb242.jpg);
                                }
                            }
                            h2 {
                                .pa;
                                .fl;
                                .tac;
                                width: 100%;
                                letter-spacing: 0.137em;
                                font-size: 0.81em;
                                transform: translateY(-50%);
                                -moz-transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                                -o-transform: translateY(-50%);
                                top: 50%;
                                .dn;
                                font-weight: 700;
                                &.visible {
                                    .db;
                                }
                                a {
                                    .tuc;
                                    color: @bianco;
                                    font-weight: 700;
                                }
                                &.bg-oro {
                                    color: @bianco;
                                    a {
                                        color: @bianco;
                                    }
                                }
                                &.bg-magenta {
                                    color: @bianco;
                                    a {
                                        color: @bianco;
                                    }
                                }
                                &.bg-viola {
                                    color: @bianco;
                                    a {
                                        color: @bianco;
                                    }
                                }
                            }
                            &.noContent {
                                background: @nero;
                                .logoInterno {
                                    .pa;
                                    .fl;
                                    width: 100%;
                                    height: 100%;
                                    background: url("@{imgs}logo-albinea-canali.png") center center no-repeat;
                                    background-size: 50% auto;
                                    opacity: 1;
                                }
                            }
                            /*&:hover{
                                .imgContainer{background:rgba(0, 0, 0, 0.7); -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out;
                                }
                            }*/
                            .overlay {
                                .dn;
                                .pa;
                                .fl;
                                width: 100%;
                                height: 100%;
                                top: 0;
                                background: transparent;
                                a {
                                    .pr;
                                    .fl;
                                    width: 100%;
                                    height: 100%;
                                }
                                .content {
                                    .pa;
                                    .fl;
                                    .tac;
                                    width: 70%;
                                    padding: 0 15%;
                                    transform: translateY(-50%);
                                    -moz-transform: translateY(-50%);
                                    -webkit-transform: translateY(-50%);
                                    -o-transform: translateY(-50%);
                                    top: 50%;
                                    p {
                                        font-size: 0.81em;
                                        line-height: 1.3em;
                                        color: @bianco;
                                        letter-spacing: 1px;
                                    }
                                    .cta-txt {
                                        font-size: 0.81em;
                                        color: @bianco;
                                        .tuc;
                                        font-weight: 700;
                                        letter-spacing: 2.2px;
                                        span {
                                            .fl;
                                            width: 100%;
                                            .tac;
                                        }
                                    }
                                    .cta-txt::before {
                                        content: '';
                                        width: 14px;
                                        height: 7px;
                                        background: @bianco;
                                        display: inline-block;
                                        margin-bottom: 0px;
                                        opacity: .8;
                                    }
                                }
                                &.bg-oro {
                                    //background:@hoverOro;
                                    .cta-txt::before {
                                        background: @oroScuro;
                                    }
                                }
                                &.bg-magenta {
                                    //background:@hoverMagenta;
                                    .cta-txt::before {
                                        background: @magentaScuro;
                                    }
                                }
                                &.bg-viola {
                                    //background:@hoverViola;
                                    .cta-txt::before {
                                        background: @violaScuro;
                                    }
                                }
                            }
                        }
                        &.sez4 {
                            .boxSezione {
                                height: 50%;
                                &.premio {
                                    background: transparent;
                                    h2 {
                                        font-size: 1.31em;
                                        color: @bianco;
                                    }
                                    &:nth-child(1),
                                    &:nth-child(4) {
                                        background: rgba(108, 83, 20, 0.7);
                                    }
                                    ul {
                                        .fl;
                                        width: 100%;
                                        li {
                                            a {
                                                .tal;
                                                color: #333;
                                                font-size: 13px;
                                                line-height: 23px;
                                                font-weight: 500;
                                                padding: 12px 0 12px 50px;
                                                background: url("@{imgs}file-icon.png") left center no-repeat;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &.sez6 {
                            .boxSezione {
                                height: 33.333%;
                                &.noContent {
                                    background: @nero;
                                    .logoInterno {
                                        background-size: 50% auto;
                                    }
                                }
                                &.premio {
                                    background: transparent;
                                    h2 {
                                        font-size: 1.31em;
                                        color: @bianco;
                                    }
                                    &:nth-child(1),
                                    &:nth-child(4) {
                                        background: rgba(108, 83, 20, 0.7);
                                    }
                                    ul {
                                        .fl;
                                        width: 100%;
                                        li {
                                            a {
                                                .tal;
                                                color: #fff;
                                                font-size: 13px;
                                                line-height: 23px;
                                                font-weight: 500;
                                                padding: 12px 0 12px 50px;
                                                background: url("@{imgs}file-icon.png") left center no-repeat;
                                            }
                                        }
                                    }
                                    &:hover{
                                        .pointernone {
                                            pointer-events: none;
                                    }                                        
                                    }
                                }
                            }
                        }
                        &.sez3 {
                            .boxSezione {
                                width: 100%;
                                height: 33.333%;
                                &.noContent {
                                    background: @nero;
                                    .logoInterno {
                                        background-size: 30% auto;
                                    }
                                }
                            }
                        }
                        .premio {
                            cursor: pointer;
                        }
                    }
                    .post {
                        .fl;
                        width: 100%;
                        min-height: 82vh;
                        .filtroEventi {
                            .fl;
                            width: 100%;
                            .tac;
                            margin: 60px 0 10px 0;
                            .label {
                                .fl;
                                width: 100%;
                                .tac;
                                font-size: 1.01em;
                                color: @viola;
                                font-family: @ral;
                                font-weight: bold;
                                margin-bottom: 15px;
                            }
                            select {
                                display: inline-block;
                                border: 3px solid @nero;
                                padding: 5px;
                                border-radius: 0;
                                -webkit-appearance: none;
                                height: 47px;
                                .tac;
                                background: @bianco;
                                background: url("@{imgs}ico-select.png") calc(~"100% - 5px") center no-repeat @bianco;
                                background-size: auto 15px;
                                padding-right: 20px;
                                margin: 5px 12px;
                                font-family: @ral;
                                font-size: 1.01em;
                                font-weight: bold;
                                &#mese {
                                    width: 200px;
                                }
                                &#anno {
                                    width: 100px;
                                }
                            }
                        }
                        h2.noEvent {
                            .fl;
                            width: 100%;
                            .tac;
                            margin: 2% 0;
                        }
                        .slideShow {
                            .fl;
                            width: 100%;
                            img,
                            iframe {
                                .fl;
                                width: 100%;
                            }
                            iframe {
                                min-height: 65vh;
                            }
                            .slick-slide {
                                .fl;
                                width: 100%;
                                img {
                                    .fl;
                                    width: 100%;
                                }
                            }
                            .slick-dots {
                                bottom: 0;
                                li {
                                    width: 30px;
                                    button {
                                        background: @bianco;
                                        width: 30px;
                                        height: 7px;
                                        padding: 0;
                                    }
                                    button:before {
                                        .dn;
                                    }
                                }
                            }
                        }
                        #map {
                            .fl;
                            width: 100%;
                            height: 450px;
                        }
                        header {
                            .fl;
                            width: 66%;
                            margin: 2% 17%;
                            .tac;
                            h2 {
                                .fl;
                                .tuc;
                                width: 100%;
                                font-size: 0.81em;
                                font-weight: 900;
                                color: @grigioScuro;
                                letter-spacing: 2.2px;
                                margin: 0;
                            }
                            &::before {
                                margin-bottom: 14px;
                                content: '';
                                width: 15px;
                                height: 7px;
                                background: @bianco;
                                display: inline-block;
                                opacity: .8;
                            }
                            &.pin-oro::before {
                                background: @oroScuro;
                            }
                            &.pin-magenta::before {
                                background: @magentaScuro;
                            }
                            &.pin-viola::before {
                                background: @violaScuro;
                            }
                        }
                        .cta-txt {
                            &.button {
                                .fl;
                                .tac;
                                font-size: 0.78;
                                .tuc;
                                color: @oroMedio;
                                border: 3px solid @oroMedio;
                                width: 200px;
                                margin: 0 17% 4% 17%;
                                padding: 10px 20px;
                                font-weight: 700;
                                webkit-transition: all 0.7s ease-in-out;
                                -moz-transition: all 0.7s ease-in-out;
                                -o-transition: all 0.7s ease-in-out;
                                transition: all 0.7s ease-in-out;
                                &:hover {
                                    background: @oroMedio;
                                    color: @bianco;
                                }
                                &.btn-map {
                                    .tuc;
                                    margin: 2% 0;
                                    margin-left: calc(~"50% - 123px");
                                }
                            }
                        }
                        .text {
                            .fl;
                            width: 66%;
                            margin: 2% 17%;
                            font-size: 0.87em;
                            line-height: 2.35em;
                            letter-spacing: 1.2px;
                            p {
                                margin-bottom: 25px;
                                a {
                                    color: @nero;
                                }
                            }
                            ul {
                                list-style: outside disc;
                            }
                            &.contactArea {
                                .tac;
                            }
                            .cta-txt {
                                &.button {
                                    float: none;
                                    width: auto;
                                    margin: 25px auto;
                                    display: inline-block;
                                }
                            }
                            iframe {
                                .fl;
                                width: 100%;
                                margin-bottom: 20px;
                            }
                        }
                        .shareButton {
                            .tac;
                            .fl;
                            width: 100%;
                            margin-bottom: 40px;
                            h3 {
                                .fl;
                                width: 100%;
                                .tac;
                                color: @grigioScuro;
                                .tuc;
                                font-size: 0.81em;
                                letter-spacing: 2.2px;
                            }
                            a {
                                width: 47px;
                                height: 47px;
                                border: 3px solid @nero;
                                display: inline-block;
                                -webkit-transition: all 0.7s ease-in-out;
                                -moz-transition: all 0.7s ease-in-out;
                                -o-transition: all 0.7s ease-in-out;
                                transition: all 0.7s ease-in-out;
                                &.twitter_button {
                                    background: url("@{imgs}ico-share-twitter.png") center center no-repeat;
                                    background-size: 20px auto;
                                    &:hover {
                                        background: url("@{imgs}ico-share-twitter-b.png") center center no-repeat @nero;
                                        background-size: 20px auto;
                                    }
                                }
                                &.facebook_button {
                                    background: url("@{imgs}ico-share-facebook.png") center center no-repeat;
                                    background-size: 20px auto;
                                    &:hover {
                                        background: url("@{imgs}ico-share-facebook-b.png") center center no-repeat @nero;
                                        background-size: 20px auto;
                                    }
                                }
                            }
                        }
                        .listaProdotti {
                            .fl;
                            width: 100%;
                            .prodotto {
                                .pr;
                                .fl;
                                width: 100%;
                                height: 75%;
                                .contenutoProdotto {
                                    .fl;
                                    width: 40%;
                                    padding: 5%;
                                    height: 100%;
                                    .centerCont {
                                        .pa;
                                        .fl;
                                        width: 100%;
                                        transform: translateY(-50%);
                                        -moz-transform: translateY(-50%);
                                        -webkit-transform: translateY(-50%);
                                        -o-transform: translateY(-50%);
                                        top: 50%;
                                        h2 {
                                            font-size: 0.81em;
                                            letter-spacing: 2.2px;
                                            font-weight: 900;
                                            .tuc;
                                            a {
                                                color: @oroMedio;
                                            }
                                            &::before {
                                                margin-bottom: 10px;
                                                content: '';
                                                width: 14px;
                                                height: 7px;
                                                background: @oroScuro;
                                                display: inline-block;
                                                opacity: .8;
                                                margin-right: calc(~"100% - 14px");
                                            }
                                        }
                                        .type {
                                            .fl;
                                            width: 100%;
                                            font-weight: 700;
                                            font-size: 0.81em;
                                            letter-spacing: 1.2px;
                                        }
                                        .text {
                                            .fl;
                                            width: 100%;
                                            font-weight: 400;
                                            font-size: 0.81em;
                                            letter-spacing: 1.2px;
                                            margin: 0;
                                            line-height: 1.3em;
                                            p {
                                                margin: 0;
                                            }
                                        }
                                        .cta-download {
                                            .fl;
                                            font-size: 0;
                                            border: 3px solid @oroMedio;
                                            width: 47px;
                                            height: 47px;
                                            background: url("@{imgs}ico-arrow-download.png") center center no-repeat;
                                            background-size: 14px auto;
                                            margin-top: 20px;
                                            -webkit-transition: all 0.7s ease-in-out;
                                            -moz-transition: all 0.7s ease-in-out;
                                            -o-transition: all 0.7s ease-in-out;
                                            transition: all 0.7s ease-in-out;
                                            &:hover {
                                                background: url("@{imgs}ico-arrow-download-on.png") center center no-repeat @oroMedio;
                                                background-size: 14px auto;
                                            }
                                        }
                                    }
                                }
                                .fotoProdotto {
                                    .fr;
                                    width: 50%;
                                    height: 95%;
                                    padding: 2.5% 0;
                                    img {
                                        .fl;
                                        height: 100%;
                                        width: auto;
                                    }
                                }
                            }
                            .prodotto:nth-child(odd) {
                                .contenutoProdotto {
                                    .fr;
                                }
                                .fotoProdotto {
                                    .fl;
                                    img {
                                        .fr;
                                    }
                                }
                            }
                            .prodotto:nth-child(even) {
                                .contenutoProdotto {
                                    .fl;
                                }
                                .fotoProdotto {
                                    .fr;
                                    img {
                                        .fl;
                                    }
                                }
                            }
                        }
                        .container_post_news {
                            &.marginBottom {
                                .fl;
                                width: 100%;
                                margin-bottom: 80px;
                            }
                        }
                        .col50,
                        .col100 {
                            .fl;
                            .tac;
                            width: 47%;
                            margin: 0 1.5%;
                            margin-top: 60px;
                            .info {
                                .fl;
                                width: 100%;
                                font-weight: 700;
                                letter-spacing: 0.5px;
                                color: @testo;
                                span {
                                    font-size: 0.81em;
                                }
                                h2 {
                                    font-weight: 400;
                                    font-size: 1.0em;
                                    margin: 0;
                                    line-height: 1em;
                                    a {
                                        color: @testo;
                                    }
                                }
                            }
                            figure {
                                .fl;
                                width: 100%;
                                margin: 25px 0;
                                img {
                                    .fl;
                                    width: 100%;
                                }
                            }
                            .text {
                                .fl;
                                padding: 0 13%;
                                width: 74%;
                                text-align: justify;
                                margin: 0;
                                p {
                                    .fl;
                                    width: 100%;
                                    font-size: 0.95em;
                                    line-height: 1.3em;
                                    letter-spacing: 1px;
                                    margin: 0;
                                }
                            }
                            /*.cta-arrow{
                                .fl; font-size:0; border:3px solid @nero; width:47px; height:47px; margin-left:calc(~"50% - 26.5px"); background: url("@{imgs}ico-cta-arrow.png") center center no-repeat; background-size:17px auto; margin-top:20px; margin-bottom:30px; -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out;
                                &:hover{background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero; background-size:17px auto;}
                            }*/
                            .actionUser {
                                .tac;
                                .fl;
                                width: 100%;
                                margin-top: 20px;
                                /*.cta-arrow{
                                    font-size:0; border:3px solid @nero; width:47px; height:47px; margin-left:calc(~"50% - 26.5px"); background: url("@{imgs}ico-cta-arrow.png") center center no-repeat; background-size:17px auto; -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out; margin-left:20px; display:inline-block;
                                    &:hover{background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero; background-size:17px auto;}
                                }*/
                                .social_button {
                                    margin-right: 15px;
                                    width: 47px;
                                    height: 47px;
                                    border: 3px solid @nero;
                                    display: inline-block;
                                    -webkit-transition: all 0.7s ease-in-out;
                                    -moz-transition: all 0.7s ease-in-out;
                                    -o-transition: all 0.7s ease-in-out;
                                    transition: all 0.7s ease-in-out;
                                    &.twitter_button {
                                        background: url("@{imgs}ico-share-twitter.png") center center no-repeat;
                                        background-size: 20px auto;
                                        &:hover {
                                            background: url("@{imgs}ico-share-twitter-b.png") center center no-repeat @nero;
                                            background-size: 20px auto;
                                        }
                                    }
                                    &.facebook_button {
                                        background: url("@{imgs}ico-share-facebook.png") center center no-repeat;
                                        background-size: 20px auto;
                                        &:hover {
                                            background: url("@{imgs}ico-share-facebook-b.png") center center no-repeat @nero;
                                            background-size: 20px auto;
                                        }
                                    }
                                    &.cta-arrow {
                                        background: url("@{imgs}ico-cta-arrow.png") center center no-repeat;
                                        background-size: 17px auto;
                                        margin-left: 25px;
                                        &:hover {
                                            background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero;
                                            background-size: 17px auto;
                                        }
                                    }
                                }
                            }
                            &::before {
                                content: '';
                                width: 14px;
                                height: 7px;
                                background: @bianco;
                                display: inline-block;
                                margin-bottom: 5px;
                            }
                            &.boxSala {
                                h2 {
                                    font-weight: 700;
                                }
                                .sottotitolo_sala {
                                    font-weight: 400;
                                }
                                .text {
                                    p {
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                        }
                        .topSala {
                            h2 {
                                font-weight: 800;
                                color: @testo;
                                text-transform: none;
                                font-size: 0.94em;
                            }
                            p {
                                font-size: 0.81em;
                                letter-spacing: 1.2px;
                            }
                        }
                        .halfCol {
                            .fl;
                            .tac;
                            width: 44%;
                            margin: 0 1.5% 0 3%;
                            margin-top: 50px;
                            .text {
                                margin: 0;
                                .fl; //.tal;
                                text-align: justify;
                                width: 100%;
                                font-size: 0.81em;
                                line-height: 1.5em;
                                p {
                                    font-size: 1em;
                                    line-height: 1.5em;
                                    margin: 0;
                                }
                                .caratteristiche {
                                    .fl;
                                    width: 100%;
                                    margin-top: 20px;
                                    p {
                                        margin-top: 10px;
                                        strong {
                                            text-transform: capitalize;
                                        }
                                    }
                                }
                            }
                            figure {
                                .fl;
                                width: 100%;
                                margin-bottom: 30px;
                                img {
                                    .fl;
                                    width: 100%;
                                }
                                &.Sth {
                                    margin-bottom: 0;
                                }
                            }
                            &.noMargin {
                                width: 50%;
                                .fr;
                                margin-left: 1.5%;
                                margin-right: 0;
                            }
                        }
                        .col100 {
                            width: 97%;
                            margin-top: 0;
                            .actionUser .social_button.cta-arrow {
                                margin-right: 0;
                            }
                        }
                        .clear {
                            .fl;
                            width: 100%;
                            clear: both;
                            &.moreMargin {
                                margin-bottom: 60px;
                            }
                        }
                        &.lista-post {
                            margin-top: 2%;
                        }
                        &.pin-oro {
                            .slideShow {
                                .slick-dots {
                                    li {
                                        &.slick-active {
                                            button {
                                                background: @oroScuro;
                                            }
                                        }
                                    }
                                }
                            }
                            header::before {
                                background: @oroScuro;
                            }
                            .col50::before,
                            .col100::before {
                                background: @oro;
                            }
                        }
                        &.pin-magenta {
                            .slideShow {
                                .slick-dots {
                                    li {
                                        &.slick-active {
                                            button {
                                                background: @magentaScuro;
                                            }
                                        }
                                    }
                                }
                            }
                            header::before {
                                background: @magentaScuro;
                            }
                            .col50::before,
                            .col100::before {
                                background: @magenta;
                            }
                        }
                        &.pin-viola {
                            .slideShow {
                                .slick-dots {
                                    li {
                                        &.slick-active {
                                            button {
                                                background: @violaScuro;
                                            }
                                        }
                                    }
                                }
                            }
                            header::before {
                                background: @violaScuro;
                            }
                            .col50::before,
                            .col100::before {
                                background: @viola;
                            }
                        }
                        &.news-eventi {
                            .text {
                                .tac;
                            }
                        }
                        &.colProdotto {
                            .pr;
                            overflow: hidden;
                            height: calc(~"100% - 122px");
                            min-height: calc(~"100% - 122px");
                            /*.boxFotoBig{
                                .pr; .fl; .tac; width:50%; height:98%; overflow: hidden; padding:1% 0%;
                                img{ display:inline-block; height:100%; width:auto;}
                            }*/
                            .contenutoProdotto {
                                .pr;
                                .fl;
                                width: 35%;
                                height: 98%;
                                padding: 1% 5% 1% 60%;
                                overflow-y: auto;
                                padding-left: 60%;
                                background-size: auto 98%;
                                background-position: left center;
                                background-repeat: no-repeat;
                                header {
                                    margin: 2% 5%;
                                    width: 90%;
                                    h2 {
                                        .tal;
                                        font-size: 1.12em;
                                        letter-spacing: 2px;
                                        font-weight: 900;
                                        .tuc;
                                        color: @oroMedio;
                                    }
                                }
                                .text {
                                    margin: 2% 5%;
                                    width: 90%;
                                    .sottotesto {
                                        .fl;
                                        width: calc(~"100% - 25px");
                                        padding-right: 25px;
                                        margin-bottom: 30px;
                                        h3 {
                                            .tal;
                                            font-size: 0.95em;
                                            letter-spacing: 2px;
                                            font-weight: 900;
                                            .tuc;
                                            color: @oroMedio;
                                            margin: 0;
                                            line-height: 1.0em;
                                            &::before {
                                                margin-bottom: 10px;
                                                content: '';
                                                width: 14px;
                                                height: 7px;
                                                background: @oroScuro;
                                                display: inline-block;
                                                opacity: .8;
                                                margin-right: calc(~"100% - 14px");
                                            }
                                        }
                                        p {
                                            line-height: 1.76em;
                                            font-size: 0.95em;
                                        }
                                    }
                                }
                                .button {
                                    margin-left: 5%;
                                }
                            }
                        }
                    }
                    .navBar {
                        .fl;
                        width: 100%;
                        span {
                            .fl;
                            margin: 20px 3%;
                            font-size: 0.625em;
                            color: @testo;
                            .tuc;
                            letter-spacing: 2px;
                            cursor: pointer;
                            strong {
                                font-weight: 800;
                            }
                        }
                    }
                    .filterView {
                        .pa;
                        .fl;
                        top: 140px;
                        width: 100%;
                        .tac; //height:100px;
                        li {
                            padding: 5px 20px 5px 40px;
                            width: auto;
                            display: inline-block;
                            .tuc;
                            font-size: 10px;
                            letter-spacing: 0.220em;
                            cursor: pointer;
                        }
                        &.black {
                            li {
                                color: @bianco;
                                border-right: 1px solid @bianco;
                                &.attivaLista {
                                    background: url("@{imgs}icon-lista-b.png") left center no-repeat;
                                    margin-right: 20px;
                                }
                                &.attivaPiantina {
                                    background: url("@{imgs}icon-piantina-b.png") left center no-repeat;
                                    border-right: 0;
                                    font-weight: 900;
                                    padding-right: 0;
                                }
                            }
                        }
                        &.white {
                            li {
                                color: @nero;
                                border-right: 1px solid @nero;
                                &.attivaLista {
                                    background: url("@{imgs}icon-lista-n.png") left center no-repeat;
                                    font-weight: 900;
                                    margin-right: 20px;
                                }
                                &.attivaPiantina {
                                    background: url("@{imgs}icon-piantina-n.png") left center no-repeat;
                                    border-right: 0;
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                    #piantinaSale {
                        .fl;
                        width: 100%;
                        background: @nero;
                        height: calc(~"100vh - 122px");
                        figure {
                            .fl;
                            width: 100%;
                            .tac; //margin-top:20px;
                            img {
                                display: inline-block;
                                height: auto;
                                max-width: 80%;
                                width: auto;
                            }
                        }
                    }
                    #paginaLeSale {
                        .dn;
                        margin-top: 15px;
                    }
                }
                .col25 {
                    .oh;
                    .bread {
                        .pa;
                        .fl;
                        width: 100%;
                        top: 100px;
                        z-index: 21;
                        left: 6%;
                        span {
                            .fl;
                            a {
                                .fl;
                                .tuc;
                                color: @bianco;
                                font-size: 0.62em;
                                letter-spacing: 2.2px;
                                font-weight: 400;
                                padding-right: 15px;
                                margin-right: 15px;
                                border-right: 1px solid @bianco;
                                &.noBorder {
                                    border-right: 0;
                                }
                            }
                        }
                        .breadcrumb_last {
                            .fl;
                            .tuc;
                            color: @bianco;
                            font-size: 0.62em;
                            letter-spacing: 2.2px;
                            font-weight: 400;
                            padding-right: 15px;
                            margin-right: 15px;
                            font-weight: 700;
                            &.in_event {
                                .dn;
                            }
                        }
                    }
                    .contenutoSezione {
                        .pa;
                        .fl;
                        width: 88%;
                        margin-left: 6%;
                        transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        top: 50%;
                        h1 {
                            .tuc;
                            color: @bianco;
                            font-size: 0.81em;
                            letter-spacing: 2.2px;
                            margin-bottom: 22px;
                            font-weight: 900;
                        }
                        h1::before {
                            .fl;
                            margin-right: calc(~"100% - 14px");
                            margin-bottom: 15px;
                            content: '';
                            width: 14px;
                            height: 7px;
                            background: @bianco;
                            display: inline-block;
                            opacity: .8;
                        }
                        .summary {
                            .fl;
                            width: 100%;
                            font-size: 0.81em;
                            letter-spacing: 1px;
                            line-height: 1.7em;
                            color: @bianco;
                            font-weight: 400;
                            p {
                                .fl;
                                width: 100%;
                                margin-bottom: 22px;
                                margin-top: 0;
                            }
                            .wrapped {
                                .dn;
                                .fl;
                                width: 100%;
                            }
                            .readMore {
                                .fl;
                                font-size: 2.18em;
                                font-weight: 700;
                                border: 3px solid @bianco;
                                padding: 1.2% 2%;
                                margin: 20px 0;
                                cursor: pointer;
                                -webkit-transition: all 0.7s ease;
                                -moz-transition: all 0.7s ease;
                                -o-transition: all 0.7s ease;
                                transition: all 0.7s ease;
                                &:hover {
                                    background: @bianco;
                                    color: @nero;
                                }
                            }
                        }
                        .socialButton {
                            .fl;
                            width: 100%;
                            margin-top: 30px;
                            h2 {
                                .tuc;
                                color: @bianco;
                                font-size: 0.81em;
                                letter-spacing: 2.2px;
                                margin-bottom: 22px;
                                font-weight: 900;
                            }
                            .s-button {
                                .fl;
                                width: 47px;
                                height: 47px;
                                margin-right: 15px;
                                border: 3px solid @bianco;
                                cursor: pointer;
                                -webkit-transition: all 0.7s ease;
                                -moz-transition: all 0.7s ease;
                                -o-transition: all 0.7s ease;
                                transition: all 0.7s ease;
                                &.facebook {
                                    background: url("@{imgs}ico-social-fb.png") center center no-repeat;
                                    background-size: auto 20px;
                                    &:hover {
                                        background: url("@{imgs}ico-social-fb-n.png") center center no-repeat @bianco;
                                    }
                                }
                                &.instagram {
                                    background: url("@{imgs}ico-social-ig.png") center center no-repeat;
                                    background-size: auto 20px;
                                    &:hover {
                                        background: url("@{imgs}ico-social-ig-n.png") center center no-repeat @bianco;
                                    }
                                }
                                &.twitter {
                                    background: url("@{imgs}ico-social-tw.png") center center no-repeat;
                                    background-size: auto 20px;
                                    &:hover {
                                        background: url("@{imgs}ico-social-tw-n.png") center center no-repeat @bianco;
                                    }
                                }
                            }
                        }
                    }
                    &.bg-oro {
                        //background-color: rgb(212, 154, 10);
                        //background-blend-mode: multiply, normal;
                        .contenutoSezione {
                            h1::before {
                                background: @oro;
                            }
                        }
                    }
                    &.bg-magenta {
                        //background-color: rgba(143, 0, 0, 0.8);
                        //background-blend-mode: multiply, normal;
                        .contenutoSezione {
                            h1::before {
                                background: @magenta;
                            }
                        }
                    }
                    &.bg-viola {
                        //background-color: rgba(124, 1, 44, 0.8);
                        //background-blend-mode: multiply, normal;
                        .contenutoSezione {
                            h1::before {
                                background: @viola;
                            }
                        }
                    }
                }
                /*
                .loadMore{
                    .cont{display: inline-block; padding:15px 20px; -webkit-transition: all 0.2s;-moz-transition: all 0.2s;-o-transition: all 0.2s;transition: all 0.2s;
                                &:hover{
                                    -webkit-box-shadow: inset 0 0 0 3px #000000;
                                      -moz-box-shadow: inset 0 0 0 3px #000000;
                                      box-shadow: inset 0 0 0 3px #000000;
                                }
                            }
                    .fl; .tuc; width: 100%; .tac; font-weight:800; letter-spacing:2.2px; font-size:0.81em; margin:60px 0; cursor:pointer;
                }*/
                .loadMore {
                    .fl;
                    .tuc;
                    width: 100%;
                    .tac;
                    font-weight: 800;
                    letter-spacing: 2.2px;
                    font-size: 0.81em;
                    margin: 0px 0 30px 0;
                    cursor: pointer;
                    .cont {
                        display: inline-block;
                        padding: 15px 20px;
                        -webkit-transition: all 0.2s;
                        -moz-transition: all 0.2s;
                        -o-transition: all 0.2s;
                        transition: all 0.2s;
                        &:hover {
                            -webkit-box-shadow: inset 0 0 0 3px #000000;
                            -moz-box-shadow: inset 0 0 0 3px #000000;
                            box-shadow: inset 0 0 0 3px #000000;
                        }
                    }
                }
            }
        }
    }
    &.opened {
        //background: url("@{imgs}bg-header-opened.jpg");
        //background-size: cover; background-repeat: no-repeat; background-position: center center;
        .burgerMenu {
            background: url("@{imgs}ico-close.png") center center no-repeat;
            width: 35px;
        }
        .topBar {
            background: @nero;
            .logo {
                display: inline-block;
                img {
                    width: 285px;
                }
            }
        }
    }
    .superMenu {
        z-index: 1000;
        .topBar {
            .fl;
            .tac;
            .pa;
            top: 0;
            z-index: 10;
            width: 96%;
            padding: 20px 2%;
            height: 82px;
            background: @nero;
            .burgerMenu {
                .fl;
                background: url("@{imgs}ico-menu.png") left center repeat-x;
                width: 15px;
                height: 35px;
                cursor: pointer;
                margin-top: 20px;
                &:hover {
                    -webkit-transition: width 0.2s ease-in-out;
                    -moz-transition: width 0.2s ease-in-out;
                    -o-transition: width 0.2s ease-in-out;
                    transition: width 0.2s ease-in-out;
                    width: 35px;
                }
                &.opened {
                    background: url("@{imgs}ico-close.png") center center no-repeat;
                    width: 35px;
                }
            }
            .logo {
                .pa;
                top: 10px;
                right: 10%;
                width: 80%;
                .db;
                img {
                    width: 285px;
                }
            }
        }
        nav {
            .pa;
            .fl;
            width: 100%;
            top: 122px;
            height: calc(~"100vh - 122px");
            overflow: hidden;
            ul {
                .pa;
                .fl;
                width: 100%;
                height: 100%;
                li {
                    &.bg-oro {
                        background: @hoverOro;
                    }
                    &.bg-magenta {
                        background: @hoverMagenta;
                    }
                    &.bg-viola {
                        background: @hoverViola;
                    }
                    /*&::before{
                        .pa; top:5%; left: calc(~"50% - 7px"); content: ''; width:14px; height: 7px; background: @bianco; display:inline-block; margin-bottom:20px; opacity:.8;  -webkit-transition-property:all; -webkit-transition-duration: 0.7s; transition-property: all; transition-duration: 0.7s;
                    }
                    &:hover{
                       &::before{
                            top:14%;
                        } 
                    }*/
                    .pr;
                    .fl;
                    .tac;
                    .tuc;
                    width:33.33%;
                    height:95%;
                    padding-top:5%;
                    overflow: hidden;
                    a {
                        .fl;
                        width: 100%;
                        color: @grigio;
                        font-weight: 800;
                        letter-spacing: 2.2px;
                        font-size: 0.81em;
                        padding-bottom: 5%;
                        -webkit-transition-property: all;
                        -webkit-transition-duration: 0.7s;
                        transition-property: all;
                        transition-duration: 0.7s;
                        &:hover {
                            text-decoration: line-through;
                        }
                    }
                    ul.sub-menu {
                        .pr;
                        .fl;
                        li {
                            .fl;
                            .pr;
                            width: 100%;
                            height: auto;
                            padding: 15px 0;
                            overflow: hidden;
                            /*&::before{.pa; top:15px; left: -14px; margin-bottom: 0; margin-top: 6px;  -webkit-transition-property:all; -webkit-transition-duration: 0.7s; transition-property: all; transition-duration: 0.7s;}*/
                            a {
                                text-transform: none;
                                letter-spacing: 2;
                                font-weight: 400;
                                padding-bottom: 0;
                                -webkit-transition-property: all;
                                -webkit-transition-duration: 0.7s;
                                transition-property: all;
                                transition-duration: 0.7s;
                            }
                        }
                        li:hover::before {
                            .pa;
                            left: 32% !important;
                            .db;
                            top: 15px;
                        }
                    }
                }
            }
        }
        .lingue {
            .fr;
            margin-top: 20px;
            #lang_sel_list {
                height: auto;
                ul {
                    border-top: 0;
                    li {
                        font-size: 13px;
                        font-family: @ral;
                        color: @bianco;
                        font-weight: 500;
                        line-height: 35px;
                        a {
                            .fl;
                            background: transparent;
                            font-size: 13px;
                            font-family: @ral;
                            color: @bianco;
                            font-weight: 500;
                            letter-spacing: 2.2px;
                            line-height: 35px;
                            &.lang_sel_sel {
                                font-weight: 900;
                            }
                        }
                        &::after {
                            content: '-';
                            .fl;
                            margin: 0 6px;
                        }
                        &:last-child {
                            &::after {
                                .dn;
                            }
                        }
                    }
                }
            }
        }
    }
}

#container {
    .fl;
    width: 100%;
    margin-top: 50px;
    .col33 {
        .fl;
        .tac;
        width: 29.33%;
        margin: 0 2% 60px 2%;
        .info {
            .fl;
            width: 100%;
            font-weight: 700;
            letter-spacing: 0.5px;
            color: @testo;
            span {
                font-size: 0.81em;
            }
            h2 {
                font-weight: 400;
                font-size: 1.0em;
                margin: 0;
                line-height: 1em;
                a {
                    color: @testo;
                }
            }
        }
        figure {
            .fl;
            width: 100%;
            margin: 25px 0;
            img {
                .fl;
                width: 100%;
            }
        }
        .text {
            .fl;
            padding: 0 13%;
            width: 74%;
            text-align: justify;
            p {
                .fl;
                width: 100%;
                font-size: 0.95em;
                line-height: 1.3em;
                letter-spacing: 1px;
            }
        }
        .actionUser {
            .tac;
            .fl;
            width: 100%;
            margin-top: 20px;
            /*.cta-arrow{
                font-size:0; border:3px solid @nero; width:47px; height:47px; margin-left:calc(~"50% - 26.5px"); background: url("@{imgs}ico-cta-arrow.png") center center no-repeat; background-size:17px auto; -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out; margin-left:20px; display:inline-block;
                &:hover{background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero; background-size:17px auto;}
            }*/
            .social_button {
                margin-right: 15px;
                width: 47px;
                height: 47px;
                border: 3px solid @nero;
                display: inline-block;
                -webkit-transition: all 0.7s ease-in-out;
                -moz-transition: all 0.7s ease-in-out;
                -o-transition: all 0.7s ease-in-out;
                transition: all 0.7s ease-in-out;
                &.twitter_button {
                    background: url("@{imgs}ico-share-twitter.png") center center no-repeat;
                    background-size: 20px auto;
                    &:hover {
                        background: url("@{imgs}ico-share-twitter-b.png") center center no-repeat @nero;
                        background-size: 20px auto;
                    }
                }
                &.facebook_button {
                    background: url("@{imgs}ico-share-facebook.png") center center no-repeat;
                    background-size: 20px auto;
                    &:hover {
                        background: url("@{imgs}ico-share-facebook-b.png") center center no-repeat @nero;
                        background-size: 20px auto;
                    }
                }
                &.cta-arrow {
                    background: url("@{imgs}ico-cta-arrow.png") center center no-repeat;
                    background-size: 17px auto;
                    margin-left: 25px;
                    &:hover {
                        background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero;
                        background-size: 17px auto;
                    }
                }
            }
        }
        &.category-albinea-canali::before {
            content: '';
            width: 14px;
            height: 7px;
            background: @oro;
            display: inline-block;
            margin-bottom: 5px;
        }
        &.category-cantina::before {
            content: '';
            width: 14px;
            height: 7px;
            background: @magenta;
            display: inline-block;
            margin-bottom: 5px;
        }
        &.category-ottocento::before {
            content: '';
            width: 14px;
            height: 7px;
            background: @viola;
            display: inline-block;
            margin-bottom: 5px;
        }
    }
    .clear {
        .fl;
        width: 100%;
        clear: both;
        &.moreMargin {
            margin-bottom: 60px;
        }
    }
    .loadMore {
        .fl;
        .tuc;
        width: 100%;
        .tac;
        font-weight: 800;
        letter-spacing: 2.2px;
        font-size: 0.81em;
        margin: 0px 0 30px 0;
        cursor: pointer;
        .cont {
            display: inline-block;
            padding: 15px 20px;
            -webkit-transition: all 0.2s;
            -moz-transition: all 0.2s;
            -o-transition: all 0.2s;
            transition: all 0.2s;
            &:hover {
                -webkit-box-shadow: inset 0 0 0 3px #000000;
                -moz-box-shadow: inset 0 0 0 3px #000000;
                box-shadow: inset 0 0 0 3px #000000;
            }
        }
    }
}

footer,
.innerFooter {
    .pr;
    .fl;
    width: 96%;
    background: @nero;
    padding: 20px 2%;
    height: 80px;
    .dataInfo {
        .pa;
        left: 2%;
        top: 20px;
        .fl;
        width: 40%;
        font-weight: 600;
        color: @bianco;
        opacity: 0.30;
        font-size: 0.81em;
        margin-top: 5px;
        a {
            color: @bianco;
            font-weight: 900;
        }
        &.right {
            right: 2%;
            left: auto;
            text-align: right;
        }
    }
    .logo {
        .fl;
        width: 100%;
        .tac;
        img {
            width: 217px;
        }
    }
    .social {
        .pa;
        .fr;
        .tar;
        top: 20px;
        right: 2%;
        font-weight: 600;
        color: @bianco;
        font-size: 0.81em;
        span {
            .fl;
            margin-top: 10px;
            margin-right: 10px;
            opacity: 0.30;
        }
        a {
            .fl;
            margin-top: 0px;
        }
    }
    .cart {
        .pa;
        .fr;
        .tar;
        top: 65px;
        right: 2%;
        font-weight: 600;
        color: @bianco;
        font-size: 0.81em;
        span {
            .fl;
            margin-top: 10px;
            margin-right: 10px;
            opacity: 0.30;
        }
        a {
            .fl;
            margin-top: 0px;
        }
    }
}

.noFooter {
    #top {
        &.closed {
            background: @nero;
        }
    }
    footer {
        .dn;
    }
}

@media (max-width: 1024px) {
    body {
        overflow: visible;
    }
    .video-overlayer {
        .content {
            max-width: 90%;
            width: 90%;
            height: 50vh;
            .buttons-wrapper {
                .fl;
                width: 100%;
                .tac;
                margin-top: 25px;
                z-index: 99999;
                .button {
                    color: @bianco;
                    padding: 10px 30px;
                    margin: 0 10px;
                    border: 3px solid @bianco;
                    webkit-transition: all 0.7s ease-in-out;
                    -moz-transition: all 0.7s ease-in-out;
                    -o-transition: all 0.7s ease-in-out;
                    transition: all 0.7s ease-in-out;
                    &:hover {
                        background: @bianco;
                        color: @nero;
                    }
                }
            }
            iframe {
                .pr;
                width: 100%;
                height: 50vh;
                z-index: 999;
                margin-bottom: 20px;
            }
        }
    }
    #top {
        height: auto;
        .pr;
        .topBar {
            padding: 0px 20px;
            /*width:calc(~"100% - 40px");*/
            .pf;
            top: 10px;
            width: auto;
            z-index: 1000;
            .logo {
                .dn;
            }
        }
        .mainContent {
            .pr;
            .col75,
            .col25,
            .col33 {
                width: 100% !important;
                margin-left: 0;
                margin-right: 0;
                height: 50% !important;
            }
        }
        nav {
            height: calc(~"100vh - 122px");
            overflow: hidden;
            ul {
                height: calc(~"100vh - 122px");
                overflow: scroll;
                li {
                    width: 100%;
                    height: 30.8%;
                    padding-top: 3%;
                    a {
                        padding-bottom: 0%;
                    }
                    ul.sub-menu {
                        li {
                            padding: 1.5% 0;
                        }
                    }
                }
            }
        }
        &.closed {
            height: auto;
            .pr;
            .topBar {
                .logo {
                    .dn;
                }
            }
            .mainContent {
                .pr;
                .col75 {
                    height: auto;
                    .logo {
                        width: 76%;
                        margin: 0 12%;
                        img {
                            width: 100%;
                        }
                    }
                    .icoScroll {
                        .dn;
                    }
                }
                .col25 {
                    .boxLink {
                        .overlay {
                            .dn !important;
                        }
                    }
                }
                &.page-lvl-1 {
                    top: 100px;
                    padding-bottom: 100px;
                    .col25 {
                        min-height: 100vh;
                        .bread {
                            width: 88%;
                        }
                        .contenutoSezione {
                            .pr;
                            float: left;
                            width: 88%;
                            margin-left: 6%;
                            top: 0%;
                            margin-top: 200px;
                            transform: none;
                            -moz-transform: none;
                            -webkit-transform: none;
                            -o-transform: none;
                        }
                    }
                    .col75 {
                        .pr;
                        .fl;
                        width: 100%;
                        height: auto !important;
                        overflow: visible;
                        .headerSide {
                            .pf;
                            top: 0;
                            .tac;
                            height: 60px;
                            z-index: 999;
                            .social {
                                .dn;
                            }
                            .logoSmall {
                                float: none;
                                width: 60%;
                                display: inline-block;
                                img {
                                    height: 60px;
                                    width: auto;
                                }
                            }
                        }
                        .category {
                            height: auto;
                            &.sez4,
                            &.sez6,
                            &.sez3 {
                                .boxSezione {
                                    .fl;
                                    width: 100%;
                                    height: 35vh;
                                    &.premio {
                                        &:nth-child(odd) {
                                            background: rgba(108, 83, 20, 0.7);
                                        }
                                        &:nth-child(4) {
                                            background: transparent;
                                        }
                                    }
                                }
                            }
                        }
                        .post {
                            .col50 {
                                width: 90%;
                                margin: 0 5%;
                                margin-top: 30px;
                                figure {
                                    margin: 15px 0;
                                }
                                .text {
                                    padding: 0 10%;
                                    width: 80%;
                                    .tac;
                                }
                                .cta-arrow {
                                    margin-left: calc(~"50% - 26.5px");
                                }
                                &.albinea-canali::before {
                                    width: 14px;
                                    background: @oroScuro;
                                    margin-bottom: 6px;
                                }
                                &.cantina::before {
                                    width: 14px;
                                    background: @magentaScuro;
                                    margin-bottom: 6px;
                                }
                                &.ottocento::before {
                                    width: 14px;
                                    background: @violaScuro;
                                    margin-bottom: 6px;
                                }
                            }
                            .loadMore {
                                margin: 40px 0 40px 0;
                            }
                            .listaProdotti {
                                .prodotto {
                                    height: 100vh;
                                    background-size: auto 50%;
                                    background-position: left top;
                                    .fotoProdotto {
                                        .fl;
                                        width: 100%;
                                        height: 50%;
                                        img {
                                            height: 80%;
                                            margin-top: 10%;
                                        }
                                    }
                                    .contenutoProdotto {
                                        .fl;
                                        .pr;
                                        width: 80%;
                                        height: 40%;
                                        padding: 5% 10%;
                                    }
                                }
                                .prodotto:nth-child(odd) {
                                    .contenutoProdotto {
                                        .fr;
                                    }
                                    .fotoProdotto {
                                        .fl;
                                        img {
                                            .fr;
                                            margin-right: 25%;
                                        }
                                    }
                                }
                                .prodotto:nth-child(even) {
                                    background-position: right top;
                                    .contenutoProdotto {
                                        .fl;
                                    }
                                    .fotoProdotto {
                                        .fr;
                                        img {
                                            .fl;
                                            margin-left: 25%;
                                        }
                                    }
                                }
                            }
                            .text {
                                text-align: justify;
                                margin: 2% 12%;
                                width: 76%;
                            }
                            &.colProdotto {
                                .fl;
                                width: 100%;
                                .contenutoProdotto {
                                    .fl;
                                    width: 90%;
                                    padding: 2% 5%;
                                    width: 90%;
                                    padding: 2% 5%;
                                    padding-top: 100vh;
                                    background-position: center 5vh;
                                    background-size: auto 90vh;
                                }
                            }
                            .halfCol {
                                .fl;
                                .tac;
                                width: 94%;
                                margin: 0 3% 0 3%;
                                margin-top: 50px;
                                .text {
                                    p {}
                                    .caratteristiche {
                                        p {
                                            strong {}
                                        }
                                    }
                                }
                                figure {
                                    margin: 15px 0 0 0;
                                    img {}
                                    &.Sth {
                                        margin-bottom: 15px;
                                    }
                                }
                                &.noMargin {
                                    width: 94%;
                                    margin: 0 3% 0 3%;
                                    .fl;
                                }
                            }
                        }
                        .filterView {
                            li {}
                            &.black {
                                li {
                                    &.attivaLista {}
                                    &.attivaPiantina {}
                                }
                            }
                            &.white {
                                li {
                                    &.attivaLista {}
                                    &.attivaPiantina {}
                                }
                            }
                        }
                        #piantinaSale {
                            figure {
                                img {}
                            }
                        }
                        #paginaLeSale {
                            margin-top: 50px;
                        }
                        /**/
                    }
                }
            }
        }
        .superMenu {
            z-index: 1000;
            width: 100vw;
            /*&.open{*/
            .topBar {
                height: 50px;
                .logo {
                    .dn;
                }
                .burgerMenu {
                    margin-top: 5px;
                }
            }
            nav {
                top: 90px;
                /*height:100%; */
                ul {
                    li {
                        width: 100%;
                        height: auto;
                        padding: 3% 0;
                        a {
                            padding-bottom: 1%;
                            font-size: 0.88em;
                        }
                        ul.sub-menu {
                            .pr;
                            .fl;
                            width: 100%;
                            li {
                                padding: 1.3% 0;
                            }
                        }
                        &::before {
                            margin-bottom: 5px;
                        }
                    }
                }
            }
            /*    }*/
        }
    }
    #container {
        .col33 {
            width: 90%;
            margin: 0 5%;
            margin-bottom: 30px;
            figure {
                margin: 15px 0;
            }
            .text {
                padding: 0 10%;
                width: 80%;
                .tac;
            }
            .cta-arrow {
                margin-left: calc(~"50% - 26.5px");
            }
            &.albinea-canali::before {
                width: 14px;
                background: @oroScuro;
                margin-bottom: 6px;
            }
            &.cantina::before {
                width: 14px;
                background: @magentaScuro;
                margin-bottom: 6px;
            }
            &.ottocento::before {
                width: 14px;
                background: @violaScuro;
                margin-bottom: 6px;
            }
        }
        .loadMore {
            margin: 10px 0 40px 0;
        }
    }
    footer,
    .innerFooter {
        .pr;
        .fl;
        width: 90%;
        padding: 5%;
        height: auto;
        overflow: hidden;
        .dataInfo {
            .pr;
            .fl;
            width: 100%;
            .tac;
            margin-top: 40px;
            top: 0;
            left: 0;
        }
        .logo {}
        .social,
        .cart {
            .pr;
            .fl;
            .tac;
            width: 100%;
            top: 0;
            right: 0;
            span {
                .fl;
                width: 100%;
            }
            a {
                float: none;
                display: inline-block;
                margin-top: 5px;
            }
        }
    }
    .noFooter {
        #top {
            &.closed {
                background: @nero;
            }
        }
        .innerFooter {
            .dn;
        }
        footer {
            .db;
        }
    }
    .home {
        #top {
            height: 100vh;
        }
    }
}

.page-template-page-premi {
    #top.closed .boxSezione.premio.custom#ajaxDiv {
        width: 100%;
        height: auto;
        background: transparent;
    }
    a.aiPremi {
        text-align: left;
        color: #fff;
        font-size: 13px;
        line-height: 23px;
        font-weight: 500;
        padding: 12px 0 12px 50px;
        cursor: pointer;
        text-transform: uppercase;
    }
    .boxSezione.premio.custom#ajaxDiv ul li a {
        position: relative;
        display: inline-block;
    }
    .post.pin-oro.custom {
        padding: 25px 0;
    }
}

.new-banner {
	position: fixed;
	bottom: 0;
	left: 0;
	text-align: center;
	display: none;
	width: 100%;

	img {
		max-width: 1366px;
		width: 100%;
		cursor: pointer;
		position: relative;
	}

	@media (max-width: 1024px) {
		.banner-desktop {
			display: none;
		}
	}

	@media (min-width: 1024px) {
		.banner-mobile {
			display: none;
		}
	}
}

.premi_riconoscimenti {
  margin-bottom: 40px;
  &__header{
    &--image-container{
      min-height: 500px;
      width: 100%;
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
    }
    &--title {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 40%;
      font-size: 32px;
      line-height: 40px;
      font-weight: 700!important;
      display: flex;
      padding: 0 60px;
      margin: 0;
      color: #fff;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(0,0,0,0.5);
      p{
        margin: 0 !important;
      }
    }    
    &--description {
      font-family: 'Playfair Display', Georgia, Times, "Times New Roman", serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      width: auto;
      display: inline-block;
      padding: 0px 60px;
      margin: 0;
      color: #fff;
    }    
  }
  &__body{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
    &--single{
      font-family: PT Sans, sans-serif;
      font-size: 30px;
      line-height: 37px;
      font-weight: 600 !important;    
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: calc(33% - 40px);
      min-height: 180px;
      margin: 20px;
      background-color: #a07e27;
      background-size: 200px;
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      &:hover{
        .premi_riconoscimenti__body--single-count{
          opacity: 1;
          display: block;
        }
        .premi_riconoscimenti__body--single-title{
          opacity: 0;
          display: none;
        }
      }
      .premi_riconoscimenti__body--single-count{
        display: none;
        margin: 0;
        opacity: 0;
      }      
      .premi_riconoscimenti__body--single-title{
        display: block;
        margin: 0;
        opacity: 1;
      }      
      @media (max-width: 780px) {
        width: calc(100% - 40px);
      }
    }    
    &.ajaxify{
      display: none;
    }
  }
  &__ajax-container{
    display: none;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
    &.ajaxify{
      display: flex;
      flex-direction: column;
    }
    &--back{
      font-family: PT Sans, sans-serif;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 1.2px;
      font-weight: 600 !important;    
      display: block;
      margin: 20px 0;     
      cursor: pointer;
    }
    &--slider{
      position: relative;
      display: block;
      width: 100%;
      max-width: 350px;
      margin: auto;      
      padding-top: 0 !important;
      background-color: #a07e27 !important;
      background-size: 200px;
      background-repeat: no-repeat;
      background-position: center;      
      &-container{
        display: block;
        width: 100%;
        background-color: #a07e27 !important;
      }
      .slick-next{
        position: absolute;
        top: 50px;
        bottom: 0;
        right: 0;
        margin: auto;
        border: none;
        color: transparent;
        background: transparent url(../img/next-black.png) 50% no-repeat !important;
        background-size: 40px !important;
        width: 40px;
        height: 40px;
        &:before{
          display: none;
        }
      }
      .slick-prev{
        position: absolute;
        top: 50px;
        left: 0;
        bottom: 0;
        margin: auto;
        border: none;
        color: transparent;
        background: transparent url(../img/prev-black.png) 50% no-repeat !important;
        background-size: 40px !important;
        width: 40px;
        height: 40px;
        &:before{
          display: none;
        }
      }
    }
    &--single{
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 200px !important;
      background-color: transparent;
      font-size: 30px;
      line-height: 37px;
      font-weight: 600 !important;        
    }
    &-items{
      max-width: 600px;
      max-height: 350px;
      overflow-y: scroll;
      margin: 40px auto;
      padding: 20px;
      background-color: #fff;
      &--single{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        font-family: PT Sans, sans-serif;
        font-size: 20px;
        line-height: 24px;
        margin: 20px 0;
        color: #000;
        img{
          width: 40px;
          margin-right: 10px;
        }
      }
    }
  }
  &__lds-dual-ring {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    background-color: rgba(255,255,255,0.8);
    &.active{
      display: flex;
    }
  }
  &__lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #a07e27;
    border-color: #a07e27 transparent #a07e27 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
}