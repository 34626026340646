/* ==========================================================================
   HTML5 Boilerplate styles - h5bp.com (generated via initializr.com)
   ========================================================================== */
html,
button,
input,
select,
textarea {
  color: #222;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
a:active {
  color: #333;
}
a,
a:focus {
  outline: none;
}
body {
  font-size: 1em;
  line-height: 1.4;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
::-webkit-input-placeholder {
  color: #CCC;
  opacity: 1 !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #CCC;
  opacity: 1 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #CCC;
  opacity: 1 !important;
}
:-ms-input-placeholder {
  color: #CCC;
  opacity: 1 !important;
}
/* ==========================================================================
   Custom styles
   ========================================================================== */
html,
body {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: grayscale;
}
html * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* http://stackoverflow.com/questions/9009377/ios-dark-gray-box-active-style */
/*** Varie ***/
.fl {
  float: left;
}
.fr {
  float: right;
}
.fn {
  float: none;
}
.pr {
  position: relative;
}
.pa {
  position: absolute;
}
.ps {
  position: static;
}
.pf {
  position: fixed;
}
.dn {
  display: none;
}
.db {
  display: block;
}
.dib {
  display: inline-block;
}
.tac {
  text-align: center;
}
.tal {
  text-align: left;
}
.tar {
  text-align: right;
}
.tuc {
  text-transform: uppercase;
}
.tul {
  text-decoration: underline;
}
.oh {
  overflow: hidden;
}
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
/*** Field generico (label + input) ***/
.field-wrap {
  overflow: hidden;
  position: relative;
}
.field-wrap.error .input-wrap {
  border-color: #ff3333;
}
.field-wrap.error .text-wrap {
  border-color: #ff3333;
}
.field-wrap.error .select-wrap {
  border-color: #ff3333;
}
.field-wrap.error .field-checkbox .checkbox-wrap .placeholder {
  border: 1px solid #ff3333;
}
.field-wrap.error .field-radio .radio-wrap .placeholder {
  border: 1px solid #ff3333;
}
.field-wrap.checked .field-radio .radio-wrap .placeholder {
  background: url("../images/radio.png") no-repeat center center;
}
.field-wrap.checked .field-checkbox .checkbox-wrap .placeholder {
  background: url("../images/checkbox.png") no-repeat center center;
}
.field-wrap label,
.field-wrap .label {
  float: left;
  font: 14px/auto Arial;
  color: #666;
  width: 33.33%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap label.empty,
.field-wrap .label.empty {
  min-height: 1px;
}
.field-wrap .input-wrap {
  border: 1px solid transparent;
  height: auto;
  padding: 2px;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .input-wrap input {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
  display: block;
  background: none;
}
.field-wrap .text-wrap {
  border: 1px solid transparent;
  padding: 2px;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .text-wrap textarea {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
  resize: none;
}
.field-wrap .select-wrap {
  border: 1px solid transparent;
  height: auto;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .select-wrap select {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
}
.field-wrap .field-checkbox .checkbox-wrap {
  float: left;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}
.field-wrap .field-checkbox .checkbox-wrap:hover {
  cursor: pointer;
}
.field-wrap .field-checkbox .checkbox-wrap input {
  position: absolute;
  top: -200%;
  left: -200%;
}
.field-wrap .field-checkbox .checkbox-wrap .placeholder {
  border: 1px solid transparent;
  display: block;
  height: 16px;
  width: 16px;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .field-checkbox label {
  display: block;
  width: 100%;
  padding-left: 35px;
  float: none;
}
.field-wrap .field-checkbox label:hover {
  cursor: pointer;
}
.field-wrap .field-radio .radio-wrap {
  float: left;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
}
.field-wrap .field-radio .radio-wrap:hover {
  cursor: pointer;
}
.field-wrap .field-radio .radio-wrap input {
  position: absolute;
  top: -200%;
  left: -200%;
}
.field-wrap .field-radio .radio-wrap .placeholder {
  border: 1px solid #ccc;
  border-radius: 50%;
  display: block;
  height: 16px;
  width: 16px;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .field-radio label {
  display: block;
  width: 100%;
  padding-left: 35px;
  float: none;
}
.field-wrap .field-radio label:hover {
  cursor: pointer;
}
.field-wrap .file-wrap {
  border: 1px solid transparent;
  height: auto;
  width: 100%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.field-wrap .file-wrap input {
  position: absolute;
  top: -100%;
  left: -100%;
}
.field-wrap .file-wrap label {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 2px;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .file-wrap label:hover {
  cursor: pointer;
}
.field-wrap .file-wrap label .button {
  position: absolute;
  right: 0;
  top: 0;
  width: 33.33%;
  background: transparent;
  text-align: center;
  color: white;
}
.field-wrap .info-wrap {
  height: auto;
  padding: 2px;
  float: left;
  width: 66.66%;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.field-wrap .info-wrap span {
  width: 100%;
  padding: 0;
  border: 0;
  height: 100%;
  display: block;
}
/*** Submit wrap ***/
.submit-wrap {
  overflow: hidden;
  margin-top: 10px;
}
.submit-wrap .submit {
  float: right;
}
.submit-wrap .feedback {
  float: left;
}
/*** Feedback ***/
.feedback {
  display: none;
}
.feedback.general {
  display: block;
  border: 1px solid green;
  color: green;
  padding: 2px 5px;
  margin: 5px 0;
  font: 14px Arial;
}
.feedback.error {
  color: red;
}
/*** Dialogs ***/
.dialog h3 {
  background: black;
  color: white;
  margin: 0;
  padding: 10px 30px 10px 10px;
  white-space: nowrap;
}
.dialog .dialog-content {
  padding: 10px;
  background: white;
}
.dialog .dialog-content p {
  margin: 0;
}
.dialog .dialog-content .buttons {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
  overflow: hidden;
}
.dialog .dialog-content .buttons a {
  float: right;
  background: black;
  color: white;
  padding: 5px 10px;
}
.dialog .dialog-content .buttons .action-confirm {
  margin-left: 10px;
}
/*** DIV con background come sfondo ***/
.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/*** Variabili ***/
body {
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  overflow: hidden;
  height: 100vh;
}
body.home {
  overflow: visible;
  height: auto;
}
.age-consent-overlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-black-90.png");
  z-index: 1000;
}
.age-consent-overlayer .content {
  max-width: 390px;
  padding: 0 10px;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}
.age-consent-overlayer .content .button {
  color: #ffffff;
  padding: 10px 30px;
  margin: 0 10px;
  border: 3px solid #ffffff;
  webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.age-consent-overlayer .content .button:hover {
  background: #ffffff;
  color: #000000;
}
.age-consent-overlayer .brand,
.age-consent-overlayer h2,
.age-consent-overlayer p {
  margin: 30px 0;
}
.age-consent-overlayer .brand img,
.age-consent-overlayer h2 img,
.age-consent-overlayer p img {
  height: auto !important;
  max-width: 100%;
}
.age-consent-overlayer p {
  font-size: 0.85em;
}
.video-overlayer {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../images/bg-black-90.png");
  z-index: 998;
}
.video-overlayer .content {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
}
.video-overlayer .content .buttons-wrapper {
  position: absolute;
  bottom: 50px;
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 25px;
  z-index: 999;
}
.video-overlayer .content .buttons-wrapper .button {
  color: #ffffff;
  padding: 10px 30px;
  margin: 0 10px;
  border: 3px solid #ffffff;
  webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
.video-overlayer .content .buttons-wrapper .button:hover {
  background: #ffffff;
  color: #000000;
}
.video-overlayer .content iframe {
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 998;
  margin-bottom: 20px;
}
@media (min-width: 1920px) {
  body {
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
  }
}
#top {
  float: left;
  position: relative;
  width: 100%;
  height: 100vh;
}
#top .topBar {
  float: left;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 10;
  padding: 20px 2%;
  height: 82px;
}
#top .topBar .burgerMenu {
  float: left;
  background: url("../images/ico-menu.png") left center repeat-x;
  width: 15px;
  height: 35px;
  cursor: pointer;
  margin-top: 20px;
}
#top .topBar .burgerMenu:hover {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  width: 35px;
}
#top .topBar .logo {
  display: none;
  position: absolute;
  top: 10px;
  right: 10%;
  width: 80%;
}
#top.closed {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}
#top.closed .mainContent {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#top.closed .mainContent .col75 {
  float: left;
  position: relative;
  width: 67%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
#top.closed .mainContent .col75 h1 {
  font-size: 0;
}
#top.closed .mainContent .col75 .logo {
  position: absolute;
  float: left;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
}
#top.closed .mainContent .col75 .logo img {
  max-width: 375px;
}
#top.closed .mainContent .col75 .icoScroll {
  position: absolute;
  background: url("../images/ico-scroll.png") center top no-repeat;
  background-size: 100% auto;
  width: 30px;
  height: 100px;
  z-index: 10;
  bottom: 20px;
  left: calc(50% - 15px);
  cursor: pointer;
}
#top.closed .mainContent .col25 {
  position: relative;
  float: left;
  width: 33%;
  height: 100%;
}
#top.closed .mainContent .col25 .boxLink {
  position: relative;
  float: left;
  width: 100%;
  height: 33.3%;
  cursor: pointer;
}
#top.closed .mainContent .col25 .boxLink .layerBg {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  opacity: 0.87;
}
#top.closed .mainContent .col25 .boxLink h2 {
  position: absolute;
  float: left;
  text-align: center;
  width: 100%;
  transform: translateY(-70%);
  -moz-transform: translateY(-70%);
  -webkit-transform: translateY(-70%);
  -o-transform: translateY(-70%);
  top: 50%;
  font-size: 0.875em;
  letter-spacing: 2.2px;
}
#top.closed .mainContent .col25 .boxLink h2 a {
  float: left;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  color: #dcdcdc;
  font-weight: 800;
  text-decoration: none;
}
#top.closed .mainContent .col25 .boxLink .overlay {
  display: none;
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  top: 0;
}
#top.closed .mainContent .col25 .boxLink .overlay a {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
#top.closed .mainContent .col25 .boxLink .overlay .content {
  position: absolute;
  float: left;
  text-align: center;
  width: 70%;
  padding: 0 15%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
}
#top.closed .mainContent .col25 .boxLink .overlay .content p {
  font-size: 0.81em;
  line-height: 1.3em;
  color: #ffffff;
  letter-spacing: 1px;
}
#top.closed .mainContent .col25 .boxLink .overlay .content .cta-txt {
  font-size: 0.81em;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2.2px;
}
#top.closed .mainContent .col25 .boxLink .overlay .content .cta-txt span {
  float: left;
  width: 100%;
  text-align: center;
}
#top.closed .mainContent .col25 .boxLink .overlay .content .cta-txt::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #ffffff;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.8;
}
#top.closed .mainContent .col25 .boxLink.bg-oro .layerBg {
  background: url("../images/bg-opacity-oro.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#top.closed .mainContent .col25 .boxLink.bg-oro h2::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #ebcd65;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.8;
}
#top.closed .mainContent .col25 .boxLink.bg-oro .overlay {
  background-color: #bb8c0d;
}
#top.closed .mainContent .col25 .boxLink.bg-oro .overlay .content .cta-txt::before {
  background: #ebcd65;
}
#top.closed .mainContent .col25 .boxLink.bg-magenta .layerBg {
  background: url("../images/bg-opacity-magenta.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#top.closed .mainContent .col25 .boxLink.bg-magenta h2::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #a22b2b;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.8;
}
#top.closed .mainContent .col25 .boxLink.bg-magenta .overlay {
  background-color: #8e0017;
}
#top.closed .mainContent .col25 .boxLink.bg-magenta .overlay .content .cta-txt::before {
  background: #a22b2b;
}
#top.closed .mainContent .col25 .boxLink.bg-viola .layerBg {
  background: url("../images/bg-opacity-viola.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
#top.closed .mainContent .col25 .boxLink.bg-viola h2::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #85365e;
  display: inline-block;
  margin-bottom: 10px;
  opacity: 0.8;
}
#top.closed .mainContent .col25 .boxLink.bg-viola .overlay {
  background-color: #7b0146;
}
#top.closed .mainContent .col25 .boxLink.bg-viola .overlay .content .cta-txt::before {
  background: #85365e;
}
#top.closed .mainContent.page-lvl-1 {
  /*
                .loadMore{
                    .cont{display: inline-block; padding:15px 20px; -webkit-transition: all 0.2s;-moz-transition: all 0.2s;-o-transition: all 0.2s;transition: all 0.2s;
                                &:hover{
                                    -webkit-box-shadow: inset 0 0 0 3px #000000;
                                      -moz-box-shadow: inset 0 0 0 3px #000000;
                                      box-shadow: inset 0 0 0 3px #000000;
                                }
                            }
                    .fl; .tuc; width: 100%; .tac; font-weight:800; letter-spacing:2.2px; font-size:0.81em; margin:60px 0; cursor:pointer;
                }*/
}
#top.closed .mainContent.page-lvl-1 .col75 {
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .headerSide {
  position: relative;
  float: left;
  width: 96%;
  background: #000000;
  padding: 20px 2%;
  height: 82px;
}
#top.closed .mainContent.page-lvl-1 .col75 .headerSide .logoSmall {
  text-align: center;
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .headerSide .logoSmall img {
  height: 82px;
  width: auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .headerSide .social {
  position: absolute;
  float: right;
  text-align: right;
  top: 30px;
  right: 2%;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.81em;
}
#top.closed .mainContent.page-lvl-1 .col75 .headerSide .social span {
  float: left;
  margin-top: 20px;
  margin-right: 10px;
  opacity: 0.3;
}
#top.closed .mainContent.page-lvl-1 .col75 .headerSide .social a {
  float: left;
  margin-top: 15px;
}
#top.closed .mainContent.page-lvl-1 .col75 .category {
  position: relative;
  float: left;
  width: 100%;
  height: calc(100vh - 122px);
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sezionePremi {
  background: url("../images/bg-premi.png") center center no-repeat #7d6425;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione {
  position: relative;
  overflow: hidden;
  float: left;
  width: 50%;
  height: 50%;
  background: #000000;
  /*&:hover{
                                .imgContainer{background:rgba(0, 0, 0, 0.7); -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out;
                                }
                            }*/
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .imgContainer {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .imgContainer img {
  opacity: 0;
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .imgContainer.ben {
  opacity: 0.65;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .imgContainer svg {
  background: url(http://4.bp.blogspot.com/-IzPWLqY4gJ0/T01CPzNb1KI/AAAAAAAACgA/_8uyj68QhFE/s400/a2cf7051-5952-4b39-aca3-4481976cb242.jpg);
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2 {
  position: absolute;
  float: left;
  text-align: center;
  width: 100%;
  letter-spacing: 0.137em;
  font-size: 0.81em;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
  display: none;
  font-weight: 700;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.visible {
  display: block;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2 a {
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 700;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.bg-oro {
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.bg-oro a {
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.bg-magenta {
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.bg-magenta a {
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.bg-viola {
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione h2.bg-viola a {
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione.noContent {
  background: #000000;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione.noContent .logoInterno {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  background: url("../images/logo-albinea-canali.png") center center no-repeat;
  background-size: 50% auto;
  opacity: 1;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay {
  display: none;
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
  top: 0;
  background: transparent;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay a {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay .content {
  position: absolute;
  float: left;
  text-align: center;
  width: 70%;
  padding: 0 15%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay .content p {
  font-size: 0.81em;
  line-height: 1.3em;
  color: #ffffff;
  letter-spacing: 1px;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay .content .cta-txt {
  font-size: 0.81em;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2.2px;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay .content .cta-txt span {
  float: left;
  width: 100%;
  text-align: center;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay .content .cta-txt::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #ffffff;
  display: inline-block;
  margin-bottom: 0px;
  opacity: 0.8;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay.bg-oro .cta-txt::before {
  background: #a3780f;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay.bg-magenta .cta-txt::before {
  background: #6c0303;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .boxSezione .overlay.bg-viola .cta-txt::before {
  background: #5c2541;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione {
  height: 50%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio {
  background: transparent;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio h2 {
  font-size: 1.31em;
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio:nth-child(1),
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio:nth-child(4) {
  background: rgba(108, 83, 20, 0.7);
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio ul {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio ul li a {
  text-align: left;
  color: #333;
  font-size: 13px;
  line-height: 23px;
  font-weight: 500;
  padding: 12px 0 12px 50px;
  background: url("../images/file-icon.png") left center no-repeat;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione {
  height: 33.333%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.noContent {
  background: #000000;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.noContent .logoInterno {
  background-size: 50% auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio {
  background: transparent;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio h2 {
  font-size: 1.31em;
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio:nth-child(1),
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio:nth-child(4) {
  background: rgba(108, 83, 20, 0.7);
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio ul {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio ul li a {
  text-align: left;
  color: #fff;
  font-size: 13px;
  line-height: 23px;
  font-weight: 500;
  padding: 12px 0 12px 50px;
  background: url("../images/file-icon.png") left center no-repeat;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio:hover .pointernone {
  pointer-events: none;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez3 .boxSezione {
  width: 100%;
  height: 33.333%;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez3 .boxSezione.noContent {
  background: #000000;
}
#top.closed .mainContent.page-lvl-1 .col75 .category.sez3 .boxSezione.noContent .logoInterno {
  background-size: 30% auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .category .premio {
  cursor: pointer;
}
#top.closed .mainContent.page-lvl-1 .col75 .post {
  float: left;
  width: 100%;
  min-height: 82vh;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .filtroEventi {
  float: left;
  width: 100%;
  text-align: center;
  margin: 60px 0 10px 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .filtroEventi .label {
  float: left;
  width: 100%;
  text-align: center;
  font-size: 1.01em;
  color: #85365e;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  margin-bottom: 15px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .filtroEventi select {
  display: inline-block;
  border: 3px solid #000000;
  padding: 5px;
  border-radius: 0;
  -webkit-appearance: none;
  height: 47px;
  text-align: center;
  background: #ffffff;
  background: url("../images/ico-select.png") calc(100% - 5px) center no-repeat #ffffff;
  background-size: auto 15px;
  padding-right: 20px;
  margin: 5px 12px;
  font-family: 'Raleway', sans-serif;
  font-size: 1.01em;
  font-weight: bold;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .filtroEventi select#mese {
  width: 200px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .filtroEventi select#anno {
  width: 100px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post h2.noEvent {
  float: left;
  width: 100%;
  text-align: center;
  margin: 2% 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow img,
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow iframe {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow iframe {
  min-height: 65vh;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow .slick-slide {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow .slick-slide img {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow .slick-dots {
  bottom: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow .slick-dots li {
  width: 30px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow .slick-dots li button {
  background: #ffffff;
  width: 30px;
  height: 7px;
  padding: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .slideShow .slick-dots li button:before {
  display: none;
}
#top.closed .mainContent.page-lvl-1 .col75 .post #map {
  float: left;
  width: 100%;
  height: 450px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post header {
  float: left;
  width: 66%;
  margin: 2% 17%;
  text-align: center;
}
#top.closed .mainContent.page-lvl-1 .col75 .post header h2 {
  float: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 0.81em;
  font-weight: 900;
  color: #cbcbcb;
  letter-spacing: 2.2px;
  margin: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post header::before {
  margin-bottom: 14px;
  content: '';
  width: 15px;
  height: 7px;
  background: #ffffff;
  display: inline-block;
  opacity: 0.8;
}
#top.closed .mainContent.page-lvl-1 .col75 .post header.pin-oro::before {
  background: #a3780f;
}
#top.closed .mainContent.page-lvl-1 .col75 .post header.pin-magenta::before {
  background: #6c0303;
}
#top.closed .mainContent.page-lvl-1 .col75 .post header.pin-viola::before {
  background: #5c2541;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .cta-txt.button {
  float: left;
  text-align: center;
  font-size: 0.78;
  text-transform: uppercase;
  color: #d49a0a;
  border: 3px solid #d49a0a;
  width: 200px;
  margin: 0 17% 4% 17%;
  padding: 10px 20px;
  font-weight: 700;
  webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .cta-txt.button:hover {
  background: #d49a0a;
  color: #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .cta-txt.button.btn-map {
  text-transform: uppercase;
  margin: 2% 0;
  margin-left: calc(50% - 123px);
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text {
  float: left;
  width: 66%;
  margin: 2% 17%;
  font-size: 0.87em;
  line-height: 2.35em;
  letter-spacing: 1.2px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text p {
  margin-bottom: 25px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text p a {
  color: #000000;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text ul {
  list-style: outside disc;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text.contactArea {
  text-align: center;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text .cta-txt.button {
  float: none;
  width: auto;
  margin: 25px auto;
  display: inline-block;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .text iframe {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton {
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 40px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton h3 {
  float: left;
  width: 100%;
  text-align: center;
  color: #cbcbcb;
  text-transform: uppercase;
  font-size: 0.81em;
  letter-spacing: 2.2px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton a {
  width: 47px;
  height: 47px;
  border: 3px solid #000000;
  display: inline-block;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton a.twitter_button {
  background: url("../images/ico-share-twitter.png") center center no-repeat;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton a.twitter_button:hover {
  background: url("../images/ico-share-twitter-b.png") center center no-repeat #000000;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton a.facebook_button {
  background: url("../images/ico-share-facebook.png") center center no-repeat;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .shareButton a.facebook_button:hover {
  background: url("../images/ico-share-facebook-b.png") center center no-repeat #000000;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto {
  position: relative;
  float: left;
  width: 100%;
  height: 75%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto {
  float: left;
  width: 40%;
  padding: 5%;
  height: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont {
  position: absolute;
  float: left;
  width: 100%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont h2 {
  font-size: 0.81em;
  letter-spacing: 2.2px;
  font-weight: 900;
  text-transform: uppercase;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont h2 a {
  color: #d49a0a;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont h2::before {
  margin-bottom: 10px;
  content: '';
  width: 14px;
  height: 7px;
  background: #a3780f;
  display: inline-block;
  opacity: 0.8;
  margin-right: calc(100% - 14px);
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont .type {
  float: left;
  width: 100%;
  font-weight: 700;
  font-size: 0.81em;
  letter-spacing: 1.2px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont .text {
  float: left;
  width: 100%;
  font-weight: 400;
  font-size: 0.81em;
  letter-spacing: 1.2px;
  margin: 0;
  line-height: 1.3em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont .text p {
  margin: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont .cta-download {
  float: left;
  font-size: 0;
  border: 3px solid #d49a0a;
  width: 47px;
  height: 47px;
  background: url("../images/ico-arrow-download.png") center center no-repeat;
  background-size: 14px auto;
  margin-top: 20px;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto .centerCont .cta-download:hover {
  background: url("../images/ico-arrow-download-on.png") center center no-repeat #d49a0a;
  background-size: 14px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .fotoProdotto {
  float: right;
  width: 50%;
  height: 95%;
  padding: 2.5% 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .fotoProdotto img {
  float: left;
  height: 100%;
  width: auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(odd) .contenutoProdotto {
  float: right;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(odd) .fotoProdotto {
  float: left;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(odd) .fotoProdotto img {
  float: right;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) .contenutoProdotto {
  float: left;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) .fotoProdotto {
  float: right;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) .fotoProdotto img {
  float: left;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .container_post_news.marginBottom {
  float: left;
  width: 100%;
  margin-bottom: 80px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 {
  float: left;
  text-align: center;
  width: 47%;
  margin: 0 1.5%;
  margin-top: 60px;
  /*.cta-arrow{
                                .fl; font-size:0; border:3px solid @nero; width:47px; height:47px; margin-left:calc(~"50% - 26.5px"); background: url("@{imgs}ico-cta-arrow.png") center center no-repeat; background-size:17px auto; margin-top:20px; margin-bottom:30px; -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out;
                                &:hover{background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero; background-size:17px auto;}
                            }*/
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .info,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .info {
  float: left;
  width: 100%;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #333333;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .info span,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .info span {
  font-size: 0.81em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .info h2,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .info h2 {
  font-weight: 400;
  font-size: 1em;
  margin: 0;
  line-height: 1em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .info h2 a,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .info h2 a {
  color: #333333;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 figure,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 figure {
  float: left;
  width: 100%;
  margin: 25px 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 figure img,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 figure img {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .text,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .text {
  float: left;
  padding: 0 13%;
  width: 74%;
  text-align: justify;
  margin: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .text p,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .text p {
  float: left;
  width: 100%;
  font-size: 0.95em;
  line-height: 1.3em;
  letter-spacing: 1px;
  margin: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser {
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 20px;
  /*.cta-arrow{
                                    font-size:0; border:3px solid @nero; width:47px; height:47px; margin-left:calc(~"50% - 26.5px"); background: url("@{imgs}ico-cta-arrow.png") center center no-repeat; background-size:17px auto; -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out; margin-left:20px; display:inline-block;
                                    &:hover{background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero; background-size:17px auto;}
                                }*/
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button {
  margin-right: 15px;
  width: 47px;
  height: 47px;
  border: 3px solid #000000;
  display: inline-block;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button.twitter_button,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.twitter_button {
  background: url("../images/ico-share-twitter.png") center center no-repeat;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button.twitter_button:hover,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.twitter_button:hover {
  background: url("../images/ico-share-twitter-b.png") center center no-repeat #000000;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button.facebook_button,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.facebook_button {
  background: url("../images/ico-share-facebook.png") center center no-repeat;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button.facebook_button:hover,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.facebook_button:hover {
  background: url("../images/ico-share-facebook-b.png") center center no-repeat #000000;
  background-size: 20px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button.cta-arrow,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.cta-arrow {
  background: url("../images/ico-cta-arrow.png") center center no-repeat;
  background-size: 17px auto;
  margin-left: 25px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50 .actionUser .social_button.cta-arrow:hover,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.cta-arrow:hover {
  background: url("../images/ico-cta-arrow-on.png") center center no-repeat #000000;
  background-size: 17px auto;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50::before,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #ffffff;
  display: inline-block;
  margin-bottom: 5px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50.boxSala h2,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100.boxSala h2 {
  font-weight: 700;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50.boxSala .sottotitolo_sala,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100.boxSala .sottotitolo_sala {
  font-weight: 400;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col50.boxSala .text p,
#top.closed .mainContent.page-lvl-1 .col75 .post .col100.boxSala .text p {
  margin-bottom: 20px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .topSala h2 {
  font-weight: 800;
  color: #333333;
  text-transform: none;
  font-size: 0.94em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .topSala p {
  font-size: 0.81em;
  letter-spacing: 1.2px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol {
  float: left;
  text-align: center;
  width: 44%;
  margin: 0 1.5% 0 3%;
  margin-top: 50px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol .text {
  margin: 0;
  float: left;
  text-align: justify;
  width: 100%;
  font-size: 0.81em;
  line-height: 1.5em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol .text p {
  font-size: 1em;
  line-height: 1.5em;
  margin: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol .text .caratteristiche {
  float: left;
  width: 100%;
  margin-top: 20px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol .text .caratteristiche p {
  margin-top: 10px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol .text .caratteristiche p strong {
  text-transform: capitalize;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol figure {
  float: left;
  width: 100%;
  margin-bottom: 30px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol figure img {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol figure.Sth {
  margin-bottom: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .halfCol.noMargin {
  width: 50%;
  float: right;
  margin-left: 1.5%;
  margin-right: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 {
  width: 97%;
  margin-top: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .col100 .actionUser .social_button.cta-arrow {
  margin-right: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .clear {
  float: left;
  width: 100%;
  clear: both;
}
#top.closed .mainContent.page-lvl-1 .col75 .post .clear.moreMargin {
  margin-bottom: 60px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.lista-post {
  margin-top: 2%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-oro .slideShow .slick-dots li.slick-active button {
  background: #a3780f;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-oro header::before {
  background: #a3780f;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-oro .col50::before,
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-oro .col100::before {
  background: #ebcd65;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-magenta .slideShow .slick-dots li.slick-active button {
  background: #6c0303;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-magenta header::before {
  background: #6c0303;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-magenta .col50::before,
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-magenta .col100::before {
  background: #a22b2b;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-viola .slideShow .slick-dots li.slick-active button {
  background: #5c2541;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-viola header::before {
  background: #5c2541;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-viola .col50::before,
#top.closed .mainContent.page-lvl-1 .col75 .post.pin-viola .col100::before {
  background: #85365e;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.news-eventi .text {
  text-align: center;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto {
  position: relative;
  overflow: hidden;
  height: calc(100% - 122px);
  min-height: calc(100% - 122px);
  /*.boxFotoBig{
                                .pr; .fl; .tac; width:50%; height:98%; overflow: hidden; padding:1% 0%;
                                img{ display:inline-block; height:100%; width:auto;}
                            }*/
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto {
  position: relative;
  float: left;
  width: 35%;
  height: 98%;
  padding: 1% 5% 1% 60%;
  overflow-y: auto;
  padding-left: 60%;
  background-size: auto 98%;
  background-position: left center;
  background-repeat: no-repeat;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto header {
  margin: 2% 5%;
  width: 90%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto header h2 {
  text-align: left;
  font-size: 1.12em;
  letter-spacing: 2px;
  font-weight: 900;
  text-transform: uppercase;
  color: #d49a0a;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto .text {
  margin: 2% 5%;
  width: 90%;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto .text .sottotesto {
  float: left;
  width: calc(100% - 25px);
  padding-right: 25px;
  margin-bottom: 30px;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto .text .sottotesto h3 {
  text-align: left;
  font-size: 0.95em;
  letter-spacing: 2px;
  font-weight: 900;
  text-transform: uppercase;
  color: #d49a0a;
  margin: 0;
  line-height: 1em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto .text .sottotesto h3::before {
  margin-bottom: 10px;
  content: '';
  width: 14px;
  height: 7px;
  background: #a3780f;
  display: inline-block;
  opacity: 0.8;
  margin-right: calc(100% - 14px);
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto .text .sottotesto p {
  line-height: 1.76em;
  font-size: 0.95em;
}
#top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto .button {
  margin-left: 5%;
}
#top.closed .mainContent.page-lvl-1 .col75 .navBar {
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col75 .navBar span {
  float: left;
  margin: 20px 3%;
  font-size: 0.625em;
  color: #333333;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
}
#top.closed .mainContent.page-lvl-1 .col75 .navBar span strong {
  font-weight: 800;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView {
  position: absolute;
  float: left;
  top: 140px;
  width: 100%;
  text-align: center;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView li {
  padding: 5px 20px 5px 40px;
  width: auto;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.22em;
  cursor: pointer;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView.black li {
  color: #ffffff;
  border-right: 1px solid #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView.black li.attivaLista {
  background: url("../images/icon-lista-b.png") left center no-repeat;
  margin-right: 20px;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView.black li.attivaPiantina {
  background: url("../images/icon-piantina-b.png") left center no-repeat;
  border-right: 0;
  font-weight: 900;
  padding-right: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView.white li {
  color: #000000;
  border-right: 1px solid #000000;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView.white li.attivaLista {
  background: url("../images/icon-lista-n.png") left center no-repeat;
  font-weight: 900;
  margin-right: 20px;
}
#top.closed .mainContent.page-lvl-1 .col75 .filterView.white li.attivaPiantina {
  background: url("../images/icon-piantina-n.png") left center no-repeat;
  border-right: 0;
  padding-right: 0;
}
#top.closed .mainContent.page-lvl-1 .col75 #piantinaSale {
  float: left;
  width: 100%;
  background: #000000;
  height: calc(100vh - 122px);
}
#top.closed .mainContent.page-lvl-1 .col75 #piantinaSale figure {
  float: left;
  width: 100%;
  text-align: center;
}
#top.closed .mainContent.page-lvl-1 .col75 #piantinaSale figure img {
  display: inline-block;
  height: auto;
  max-width: 80%;
  width: auto;
}
#top.closed .mainContent.page-lvl-1 .col75 #paginaLeSale {
  display: none;
  margin-top: 15px;
}
#top.closed .mainContent.page-lvl-1 .col25 {
  overflow: hidden;
}
#top.closed .mainContent.page-lvl-1 .col25 .bread {
  position: absolute;
  float: left;
  width: 100%;
  top: 100px;
  z-index: 21;
  left: 6%;
}
#top.closed .mainContent.page-lvl-1 .col25 .bread span {
  float: left;
}
#top.closed .mainContent.page-lvl-1 .col25 .bread span a {
  float: left;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.62em;
  letter-spacing: 2.2px;
  font-weight: 400;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col25 .bread span a.noBorder {
  border-right: 0;
}
#top.closed .mainContent.page-lvl-1 .col25 .bread .breadcrumb_last {
  float: left;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.62em;
  letter-spacing: 2.2px;
  font-weight: 400;
  padding-right: 15px;
  margin-right: 15px;
  font-weight: 700;
}
#top.closed .mainContent.page-lvl-1 .col25 .bread .breadcrumb_last.in_event {
  display: none;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione {
  position: absolute;
  float: left;
  width: 88%;
  margin-left: 6%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  top: 50%;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione h1 {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.81em;
  letter-spacing: 2.2px;
  margin-bottom: 22px;
  font-weight: 900;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione h1::before {
  float: left;
  margin-right: calc(100% - 14px);
  margin-bottom: 15px;
  content: '';
  width: 14px;
  height: 7px;
  background: #ffffff;
  display: inline-block;
  opacity: 0.8;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .summary {
  float: left;
  width: 100%;
  font-size: 0.81em;
  letter-spacing: 1px;
  line-height: 1.7em;
  color: #ffffff;
  font-weight: 400;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .summary p {
  float: left;
  width: 100%;
  margin-bottom: 22px;
  margin-top: 0;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .summary .wrapped {
  display: none;
  float: left;
  width: 100%;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .summary .readMore {
  float: left;
  font-size: 2.18em;
  font-weight: 700;
  border: 3px solid #ffffff;
  padding: 1.2% 2%;
  margin: 20px 0;
  cursor: pointer;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .summary .readMore:hover {
  background: #ffffff;
  color: #000000;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton {
  float: left;
  width: 100%;
  margin-top: 30px;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton h2 {
  text-transform: uppercase;
  color: #ffffff;
  font-size: 0.81em;
  letter-spacing: 2.2px;
  margin-bottom: 22px;
  font-weight: 900;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button {
  float: left;
  width: 47px;
  height: 47px;
  margin-right: 15px;
  border: 3px solid #ffffff;
  cursor: pointer;
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button.facebook {
  background: url("../images/ico-social-fb.png") center center no-repeat;
  background-size: auto 20px;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button.facebook:hover {
  background: url("../images/ico-social-fb-n.png") center center no-repeat #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button.instagram {
  background: url("../images/ico-social-ig.png") center center no-repeat;
  background-size: auto 20px;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button.instagram:hover {
  background: url("../images/ico-social-ig-n.png") center center no-repeat #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button.twitter {
  background: url("../images/ico-social-tw.png") center center no-repeat;
  background-size: auto 20px;
}
#top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione .socialButton .s-button.twitter:hover {
  background: url("../images/ico-social-tw-n.png") center center no-repeat #ffffff;
}
#top.closed .mainContent.page-lvl-1 .col25.bg-oro .contenutoSezione h1::before {
  background: #ebcd65;
}
#top.closed .mainContent.page-lvl-1 .col25.bg-magenta .contenutoSezione h1::before {
  background: #a22b2b;
}
#top.closed .mainContent.page-lvl-1 .col25.bg-viola .contenutoSezione h1::before {
  background: #85365e;
}
#top.closed .mainContent.page-lvl-1 .loadMore {
  float: left;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-weight: 800;
  letter-spacing: 2.2px;
  font-size: 0.81em;
  margin: 0px 0 30px 0;
  cursor: pointer;
}
#top.closed .mainContent.page-lvl-1 .loadMore .cont {
  display: inline-block;
  padding: 15px 20px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
#top.closed .mainContent.page-lvl-1 .loadMore .cont:hover {
  -webkit-box-shadow: inset 0 0 0 3px #000000;
  -moz-box-shadow: inset 0 0 0 3px #000000;
  box-shadow: inset 0 0 0 3px #000000;
}
#top.opened .burgerMenu {
  background: url("../images/ico-close.png") center center no-repeat;
  width: 35px;
}
#top.opened .topBar {
  background: #000000;
}
#top.opened .topBar .logo {
  display: inline-block;
}
#top.opened .topBar .logo img {
  width: 285px;
}
#top .superMenu {
  z-index: 1000;
}
#top .superMenu .topBar {
  float: left;
  text-align: center;
  position: absolute;
  top: 0;
  z-index: 10;
  width: 96%;
  padding: 20px 2%;
  height: 82px;
  background: #000000;
}
#top .superMenu .topBar .burgerMenu {
  float: left;
  background: url("../images/ico-menu.png") left center repeat-x;
  width: 15px;
  height: 35px;
  cursor: pointer;
  margin-top: 20px;
}
#top .superMenu .topBar .burgerMenu:hover {
  -webkit-transition: width 0.2s ease-in-out;
  -moz-transition: width 0.2s ease-in-out;
  -o-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  width: 35px;
}
#top .superMenu .topBar .burgerMenu.opened {
  background: url("../images/ico-close.png") center center no-repeat;
  width: 35px;
}
#top .superMenu .topBar .logo {
  position: absolute;
  top: 10px;
  right: 10%;
  width: 80%;
  display: block;
}
#top .superMenu .topBar .logo img {
  width: 285px;
}
#top .superMenu nav {
  position: absolute;
  float: left;
  width: 100%;
  top: 122px;
  height: calc(100vh - 122px);
  overflow: hidden;
}
#top .superMenu nav ul {
  position: absolute;
  float: left;
  width: 100%;
  height: 100%;
}
#top .superMenu nav ul li {
  /*&::before{
                        .pa; top:5%; left: calc(~"50% - 7px"); content: ''; width:14px; height: 7px; background: @bianco; display:inline-block; margin-bottom:20px; opacity:.8;  -webkit-transition-property:all; -webkit-transition-duration: 0.7s; transition-property: all; transition-duration: 0.7s;
                    }
                    &:hover{
                       &::before{
                            top:14%;
                        } 
                    }*/
  position: relative;
  float: left;
  text-align: center;
  text-transform: uppercase;
  width: 33.33%;
  height: 95%;
  padding-top: 5%;
  overflow: hidden;
}
#top .superMenu nav ul li.bg-oro {
  background: #bb8c0d;
}
#top .superMenu nav ul li.bg-magenta {
  background: #8e0017;
}
#top .superMenu nav ul li.bg-viola {
  background: #7b0146;
}
#top .superMenu nav ul li a {
  float: left;
  width: 100%;
  color: #dcdcdc;
  font-weight: 800;
  letter-spacing: 2.2px;
  font-size: 0.81em;
  padding-bottom: 5%;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.7s;
  transition-property: all;
  transition-duration: 0.7s;
}
#top .superMenu nav ul li a:hover {
  text-decoration: line-through;
}
#top .superMenu nav ul li ul.sub-menu {
  position: relative;
  float: left;
}
#top .superMenu nav ul li ul.sub-menu li {
  float: left;
  position: relative;
  width: 100%;
  height: auto;
  padding: 15px 0;
  overflow: hidden;
  /*&::before{.pa; top:15px; left: -14px; margin-bottom: 0; margin-top: 6px;  -webkit-transition-property:all; -webkit-transition-duration: 0.7s; transition-property: all; transition-duration: 0.7s;}*/
}
#top .superMenu nav ul li ul.sub-menu li a {
  text-transform: none;
  letter-spacing: 2;
  font-weight: 400;
  padding-bottom: 0;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.7s;
  transition-property: all;
  transition-duration: 0.7s;
}
#top .superMenu nav ul li ul.sub-menu li:hover::before {
  position: absolute;
  left: 32% !important;
  display: block;
  top: 15px;
}
#top .superMenu .lingue {
  float: right;
  margin-top: 20px;
}
#top .superMenu .lingue #lang_sel_list {
  height: auto;
}
#top .superMenu .lingue #lang_sel_list ul {
  border-top: 0;
}
#top .superMenu .lingue #lang_sel_list ul li {
  font-size: 13px;
  font-family: 'Raleway', sans-serif;
  color: #ffffff;
  font-weight: 500;
  line-height: 35px;
}
#top .superMenu .lingue #lang_sel_list ul li a {
  float: left;
  background: transparent;
  font-size: 13px;
  font-family: 'Raleway', sans-serif;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 2.2px;
  line-height: 35px;
}
#top .superMenu .lingue #lang_sel_list ul li a.lang_sel_sel {
  font-weight: 900;
}
#top .superMenu .lingue #lang_sel_list ul li::after {
  content: '-';
  float: left;
  margin: 0 6px;
}
#top .superMenu .lingue #lang_sel_list ul li:last-child::after {
  display: none;
}
#container {
  float: left;
  width: 100%;
  margin-top: 50px;
}
#container .col33 {
  float: left;
  text-align: center;
  width: 29.33%;
  margin: 0 2% 60px 2%;
}
#container .col33 .info {
  float: left;
  width: 100%;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #333333;
}
#container .col33 .info span {
  font-size: 0.81em;
}
#container .col33 .info h2 {
  font-weight: 400;
  font-size: 1em;
  margin: 0;
  line-height: 1em;
}
#container .col33 .info h2 a {
  color: #333333;
}
#container .col33 figure {
  float: left;
  width: 100%;
  margin: 25px 0;
}
#container .col33 figure img {
  float: left;
  width: 100%;
}
#container .col33 .text {
  float: left;
  padding: 0 13%;
  width: 74%;
  text-align: justify;
}
#container .col33 .text p {
  float: left;
  width: 100%;
  font-size: 0.95em;
  line-height: 1.3em;
  letter-spacing: 1px;
}
#container .col33 .actionUser {
  text-align: center;
  float: left;
  width: 100%;
  margin-top: 20px;
  /*.cta-arrow{
                font-size:0; border:3px solid @nero; width:47px; height:47px; margin-left:calc(~"50% - 26.5px"); background: url("@{imgs}ico-cta-arrow.png") center center no-repeat; background-size:17px auto; -webkit-transition: all 0.7s ease-in-out; -moz-transition: all 0.7s ease-in-out; -o-transition: all 0.7s ease-in-out; transition: all 0.7s ease-in-out; margin-left:20px; display:inline-block;
                &:hover{background: url("@{imgs}ico-cta-arrow-on.png") center center no-repeat @nero; background-size:17px auto;}
            }*/
}
#container .col33 .actionUser .social_button {
  margin-right: 15px;
  width: 47px;
  height: 47px;
  border: 3px solid #000000;
  display: inline-block;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}
#container .col33 .actionUser .social_button.twitter_button {
  background: url("../images/ico-share-twitter.png") center center no-repeat;
  background-size: 20px auto;
}
#container .col33 .actionUser .social_button.twitter_button:hover {
  background: url("../images/ico-share-twitter-b.png") center center no-repeat #000000;
  background-size: 20px auto;
}
#container .col33 .actionUser .social_button.facebook_button {
  background: url("../images/ico-share-facebook.png") center center no-repeat;
  background-size: 20px auto;
}
#container .col33 .actionUser .social_button.facebook_button:hover {
  background: url("../images/ico-share-facebook-b.png") center center no-repeat #000000;
  background-size: 20px auto;
}
#container .col33 .actionUser .social_button.cta-arrow {
  background: url("../images/ico-cta-arrow.png") center center no-repeat;
  background-size: 17px auto;
  margin-left: 25px;
}
#container .col33 .actionUser .social_button.cta-arrow:hover {
  background: url("../images/ico-cta-arrow-on.png") center center no-repeat #000000;
  background-size: 17px auto;
}
#container .col33.category-albinea-canali::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #ebcd65;
  display: inline-block;
  margin-bottom: 5px;
}
#container .col33.category-cantina::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #a22b2b;
  display: inline-block;
  margin-bottom: 5px;
}
#container .col33.category-ottocento::before {
  content: '';
  width: 14px;
  height: 7px;
  background: #85365e;
  display: inline-block;
  margin-bottom: 5px;
}
#container .clear {
  float: left;
  width: 100%;
  clear: both;
}
#container .clear.moreMargin {
  margin-bottom: 60px;
}
#container .loadMore {
  float: left;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-weight: 800;
  letter-spacing: 2.2px;
  font-size: 0.81em;
  margin: 0px 0 30px 0;
  cursor: pointer;
}
#container .loadMore .cont {
  display: inline-block;
  padding: 15px 20px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
#container .loadMore .cont:hover {
  -webkit-box-shadow: inset 0 0 0 3px #000000;
  -moz-box-shadow: inset 0 0 0 3px #000000;
  box-shadow: inset 0 0 0 3px #000000;
}
footer,
.innerFooter {
  position: relative;
  float: left;
  width: 96%;
  background: #000000;
  padding: 20px 2%;
  height: 80px;
}
footer .dataInfo,
.innerFooter .dataInfo {
  position: absolute;
  left: 2%;
  top: 20px;
  float: left;
  width: 40%;
  font-weight: 600;
  color: #ffffff;
  opacity: 0.3;
  font-size: 0.81em;
  margin-top: 5px;
}
footer .dataInfo a,
.innerFooter .dataInfo a {
  color: #ffffff;
  font-weight: 900;
}
footer .dataInfo.right,
.innerFooter .dataInfo.right {
  right: 2%;
  left: auto;
  text-align: right;
}
footer .logo,
.innerFooter .logo {
  float: left;
  width: 100%;
  text-align: center;
}
footer .logo img,
.innerFooter .logo img {
  width: 217px;
}
footer .social,
.innerFooter .social {
  position: absolute;
  float: right;
  text-align: right;
  top: 20px;
  right: 2%;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.81em;
}
footer .social span,
.innerFooter .social span {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  opacity: 0.3;
}
footer .social a,
.innerFooter .social a {
  float: left;
  margin-top: 0px;
}
footer .cart,
.innerFooter .cart {
  position: absolute;
  float: right;
  text-align: right;
  top: 65px;
  right: 2%;
  font-weight: 600;
  color: #ffffff;
  font-size: 0.81em;
}
footer .cart span,
.innerFooter .cart span {
  float: left;
  margin-top: 10px;
  margin-right: 10px;
  opacity: 0.3;
}
footer .cart a,
.innerFooter .cart a {
  float: left;
  margin-top: 0px;
}
.noFooter #top.closed {
  background: #000000;
}
.noFooter footer {
  display: none;
}
@media (max-width: 1024px) {
  body {
    overflow: visible;
  }
  .video-overlayer .content {
    max-width: 90%;
    width: 90%;
    height: 50vh;
  }
  .video-overlayer .content .buttons-wrapper {
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 25px;
    z-index: 99999;
  }
  .video-overlayer .content .buttons-wrapper .button {
    color: #ffffff;
    padding: 10px 30px;
    margin: 0 10px;
    border: 3px solid #ffffff;
    webkit-transition: all 0.7s ease-in-out;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
  }
  .video-overlayer .content .buttons-wrapper .button:hover {
    background: #ffffff;
    color: #000000;
  }
  .video-overlayer .content iframe {
    position: relative;
    width: 100%;
    height: 50vh;
    z-index: 999;
    margin-bottom: 20px;
  }
  #top {
    height: auto;
    position: relative;
  }
  #top .topBar {
    padding: 0px 20px;
    /*width:calc(~"100% - 40px");*/
    position: fixed;
    top: 10px;
    width: auto;
    z-index: 1000;
  }
  #top .topBar .logo {
    display: none;
  }
  #top .mainContent {
    position: relative;
  }
  #top .mainContent .col75,
  #top .mainContent .col25,
  #top .mainContent .col33 {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
    height: 50% !important;
  }
  #top nav {
    height: calc(100vh - 122px);
    overflow: hidden;
  }
  #top nav ul {
    height: calc(100vh - 122px);
    overflow: scroll;
  }
  #top nav ul li {
    width: 100%;
    height: 30.8%;
    padding-top: 3%;
  }
  #top nav ul li a {
    padding-bottom: 0%;
  }
  #top nav ul li ul.sub-menu li {
    padding: 1.5% 0;
  }
  #top.closed {
    height: auto;
    position: relative;
  }
  #top.closed .topBar .logo {
    display: none;
  }
  #top.closed .mainContent {
    position: relative;
  }
  #top.closed .mainContent .col75 {
    height: auto;
  }
  #top.closed .mainContent .col75 .logo {
    width: 76%;
    margin: 0 12%;
  }
  #top.closed .mainContent .col75 .logo img {
    width: 100%;
  }
  #top.closed .mainContent .col75 .icoScroll {
    display: none;
  }
  #top.closed .mainContent .col25 .boxLink .overlay {
    display: none !important;
  }
  #top.closed .mainContent.page-lvl-1 {
    top: 100px;
    padding-bottom: 100px;
  }
  #top.closed .mainContent.page-lvl-1 .col25 {
    min-height: 100vh;
  }
  #top.closed .mainContent.page-lvl-1 .col25 .bread {
    width: 88%;
  }
  #top.closed .mainContent.page-lvl-1 .col25 .contenutoSezione {
    position: relative;
    float: left;
    width: 88%;
    margin-left: 6%;
    top: 0%;
    margin-top: 200px;
    transform: none;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
  }
  #top.closed .mainContent.page-lvl-1 .col75 {
    position: relative;
    float: left;
    width: 100%;
    height: auto !important;
    overflow: visible;
    /**/
  }
  #top.closed .mainContent.page-lvl-1 .col75 .headerSide {
    position: fixed;
    top: 0;
    text-align: center;
    height: 60px;
    z-index: 999;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .headerSide .social {
    display: none;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .headerSide .logoSmall {
    float: none;
    width: 60%;
    display: inline-block;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .headerSide .logoSmall img {
    height: 60px;
    width: auto;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .category {
    height: auto;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione,
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione,
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez3 .boxSezione {
    float: left;
    width: 100%;
    height: 35vh;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio:nth-child(odd),
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio:nth-child(odd),
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez3 .boxSezione.premio:nth-child(odd) {
    background: rgba(108, 83, 20, 0.7);
  }
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez4 .boxSezione.premio:nth-child(4),
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez6 .boxSezione.premio:nth-child(4),
  #top.closed .mainContent.page-lvl-1 .col75 .category.sez3 .boxSezione.premio:nth-child(4) {
    background: transparent;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50 {
    width: 90%;
    margin: 0 5%;
    margin-top: 30px;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50 figure {
    margin: 15px 0;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50 .text {
    padding: 0 10%;
    width: 80%;
    text-align: center;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50 .cta-arrow {
    margin-left: calc(50% - 26.5px);
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50.albinea-canali::before {
    width: 14px;
    background: #a3780f;
    margin-bottom: 6px;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50.cantina::before {
    width: 14px;
    background: #6c0303;
    margin-bottom: 6px;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .col50.ottocento::before {
    width: 14px;
    background: #5c2541;
    margin-bottom: 6px;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .loadMore {
    margin: 40px 0 40px 0;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto {
    height: 100vh;
    background-size: auto 50%;
    background-position: left top;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .fotoProdotto {
    float: left;
    width: 100%;
    height: 50%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .fotoProdotto img {
    height: 80%;
    margin-top: 10%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto .contenutoProdotto {
    float: left;
    position: relative;
    width: 80%;
    height: 40%;
    padding: 5% 10%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(odd) .contenutoProdotto {
    float: right;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(odd) .fotoProdotto {
    float: left;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(odd) .fotoProdotto img {
    float: right;
    margin-right: 25%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) {
    background-position: right top;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) .contenutoProdotto {
    float: left;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) .fotoProdotto {
    float: right;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .listaProdotti .prodotto:nth-child(even) .fotoProdotto img {
    float: left;
    margin-left: 25%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .text {
    text-align: justify;
    margin: 2% 12%;
    width: 76%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto {
    float: left;
    width: 100%;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post.colProdotto .contenutoProdotto {
    float: left;
    width: 90%;
    padding: 2% 5%;
    padding-top: 100vh;
    background-position: center 5vh;
    background-size: auto 90vh;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .halfCol {
    float: left;
    text-align: center;
    width: 94%;
    margin: 0 3% 0 3%;
    margin-top: 50px;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .halfCol figure {
    margin: 15px 0 0 0;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .halfCol figure.Sth {
    margin-bottom: 15px;
  }
  #top.closed .mainContent.page-lvl-1 .col75 .post .halfCol.noMargin {
    width: 94%;
    margin: 0 3% 0 3%;
    float: left;
  }
  #top.closed .mainContent.page-lvl-1 .col75 #paginaLeSale {
    margin-top: 50px;
  }
  #top .superMenu {
    z-index: 1000;
    width: 100vw;
    /*&.open{*/
    /*    }*/
  }
  #top .superMenu .topBar {
    height: 50px;
  }
  #top .superMenu .topBar .logo {
    display: none;
  }
  #top .superMenu .topBar .burgerMenu {
    margin-top: 5px;
  }
  #top .superMenu nav {
    top: 90px;
    /*height:100%; */
  }
  #top .superMenu nav ul li {
    width: 100%;
    height: auto;
    padding: 3% 0;
  }
  #top .superMenu nav ul li a {
    padding-bottom: 1%;
    font-size: 0.88em;
  }
  #top .superMenu nav ul li ul.sub-menu {
    position: relative;
    float: left;
    width: 100%;
  }
  #top .superMenu nav ul li ul.sub-menu li {
    padding: 1.3% 0;
  }
  #top .superMenu nav ul li::before {
    margin-bottom: 5px;
  }
  #container .col33 {
    width: 90%;
    margin: 0 5%;
    margin-bottom: 30px;
  }
  #container .col33 figure {
    margin: 15px 0;
  }
  #container .col33 .text {
    padding: 0 10%;
    width: 80%;
    text-align: center;
  }
  #container .col33 .cta-arrow {
    margin-left: calc(50% - 26.5px);
  }
  #container .col33.albinea-canali::before {
    width: 14px;
    background: #a3780f;
    margin-bottom: 6px;
  }
  #container .col33.cantina::before {
    width: 14px;
    background: #6c0303;
    margin-bottom: 6px;
  }
  #container .col33.ottocento::before {
    width: 14px;
    background: #5c2541;
    margin-bottom: 6px;
  }
  #container .loadMore {
    margin: 10px 0 40px 0;
  }
  footer,
  .innerFooter {
    position: relative;
    float: left;
    width: 90%;
    padding: 5%;
    height: auto;
    overflow: hidden;
  }
  footer .dataInfo,
  .innerFooter .dataInfo {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    margin-top: 40px;
    top: 0;
    left: 0;
  }
  footer .social,
  .innerFooter .social,
  footer .cart,
  .innerFooter .cart {
    position: relative;
    float: left;
    text-align: center;
    width: 100%;
    top: 0;
    right: 0;
  }
  footer .social span,
  .innerFooter .social span,
  footer .cart span,
  .innerFooter .cart span {
    float: left;
    width: 100%;
  }
  footer .social a,
  .innerFooter .social a,
  footer .cart a,
  .innerFooter .cart a {
    float: none;
    display: inline-block;
    margin-top: 5px;
  }
  .noFooter #top.closed {
    background: #000000;
  }
  .noFooter .innerFooter {
    display: none;
  }
  .noFooter footer {
    display: block;
  }
  .home #top {
    height: 100vh;
  }
}
.page-template-page-premi #top.closed .boxSezione.premio.custom#ajaxDiv {
  width: 100%;
  height: auto;
  background: transparent;
}
.page-template-page-premi a.aiPremi {
  text-align: left;
  color: #fff;
  font-size: 13px;
  line-height: 23px;
  font-weight: 500;
  padding: 12px 0 12px 50px;
  cursor: pointer;
  text-transform: uppercase;
}
.page-template-page-premi .boxSezione.premio.custom#ajaxDiv ul li a {
  position: relative;
  display: inline-block;
}
.page-template-page-premi .post.pin-oro.custom {
  padding: 25px 0;
}
.new-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  text-align: center;
  display: none;
  width: 100%;
}
.new-banner img {
  max-width: 1366px;
  width: 100%;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1024px) {
  .new-banner .banner-desktop {
    display: none;
  }
}
@media (min-width: 1024px) {
  .new-banner .banner-mobile {
    display: none;
  }
}
.premi_riconoscimenti {
  margin-bottom: 40px;
}
.premi_riconoscimenti__header--image-container {
  min-height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}
.premi_riconoscimenti__header--title {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 40%;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700!important;
  display: flex;
  padding: 0 60px;
  margin: 0;
  color: #fff;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.premi_riconoscimenti__header--title p {
  margin: 0 !important;
}
.premi_riconoscimenti__header--description {
  font-family: 'Playfair Display', Georgia, Times, "Times New Roman", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  width: auto;
  display: inline-block;
  padding: 0px 60px;
  margin: 0;
  color: #fff;
}
.premi_riconoscimenti__body {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  padding: 20px;
  margin: auto;
}
.premi_riconoscimenti__body--single {
  font-family: PT Sans, sans-serif;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(33% - 40px);
  min-height: 180px;
  margin: 20px;
  background-color: #a07e27;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.premi_riconoscimenti__body--single:hover .premi_riconoscimenti__body--single-count {
  opacity: 1;
  display: block;
}
.premi_riconoscimenti__body--single:hover .premi_riconoscimenti__body--single-title {
  opacity: 0;
  display: none;
}
.premi_riconoscimenti__body--single .premi_riconoscimenti__body--single-count {
  display: none;
  margin: 0;
  opacity: 0;
}
.premi_riconoscimenti__body--single .premi_riconoscimenti__body--single-title {
  display: block;
  margin: 0;
  opacity: 1;
}
@media (max-width: 780px) {
  .premi_riconoscimenti__body--single {
    width: calc(100% - 40px);
  }
}
.premi_riconoscimenti__body.ajaxify {
  display: none;
}
.premi_riconoscimenti__ajax-container {
  display: none;
  max-width: 1200px;
  padding: 20px;
  margin: auto;
}
.premi_riconoscimenti__ajax-container.ajaxify {
  display: flex;
  flex-direction: column;
}
.premi_riconoscimenti__ajax-container--back {
  font-family: PT Sans, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 1.2px;
  font-weight: 600 !important;
  display: block;
  margin: 20px 0;
  cursor: pointer;
}
.premi_riconoscimenti__ajax-container--slider {
  position: relative;
  display: block;
  width: 100%;
  max-width: 350px;
  margin: auto;
  padding-top: 0 !important;
  background-color: #a07e27 !important;
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}
.premi_riconoscimenti__ajax-container--slider-container {
  display: block;
  width: 100%;
  background-color: #a07e27 !important;
}
.premi_riconoscimenti__ajax-container--slider .slick-next {
  position: absolute;
  top: 50px;
  bottom: 0;
  right: 0;
  margin: auto;
  border: none;
  color: transparent;
  background: transparent url(../img/next-black.png) 50% no-repeat !important;
  background-size: 40px !important;
  width: 40px;
  height: 40px;
}
.premi_riconoscimenti__ajax-container--slider .slick-next:before {
  display: none;
}
.premi_riconoscimenti__ajax-container--slider .slick-prev {
  position: absolute;
  top: 50px;
  left: 0;
  bottom: 0;
  margin: auto;
  border: none;
  color: transparent;
  background: transparent url(../img/prev-black.png) 50% no-repeat !important;
  background-size: 40px !important;
  width: 40px;
  height: 40px;
}
.premi_riconoscimenti__ajax-container--slider .slick-prev:before {
  display: none;
}
.premi_riconoscimenti__ajax-container--single {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px !important;
  background-color: transparent;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600 !important;
}
.premi_riconoscimenti__ajax-container-items {
  max-width: 600px;
  max-height: 350px;
  overflow-y: scroll;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
}
.premi_riconoscimenti__ajax-container-items--single {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-family: PT Sans, sans-serif;
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0;
  color: #000;
}
.premi_riconoscimenti__ajax-container-items--single img {
  width: 40px;
  margin-right: 10px;
}
.premi_riconoscimenti__lds-dual-ring {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
}
.premi_riconoscimenti__lds-dual-ring.active {
  display: flex;
}
.premi_riconoscimenti__lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #a07e27;
  border-color: #a07e27 transparent #a07e27 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
