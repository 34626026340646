/*** Field generico (label + input) ***/
.field-wrap { .oh; .pr;
	@errorColor: #ff3333;
	@fieldHeight: auto;
	@fieldWidth: 66.66%;
	@labelWidth: 33.33%;
	@borderColor: transparent;
	
	&.error {
		.input-wrap { border-color: @errorColor; }
		.text-wrap { border-color: @errorColor; }
		.select-wrap { border-color: @errorColor; }
		.field-checkbox .checkbox-wrap .placeholder { border: 1px solid @errorColor; }
		.field-radio .radio-wrap .placeholder { border: 1px solid @errorColor; }
	}
	&.checked {
		.field-radio .radio-wrap .placeholder { background: url("@{imgs}radio.png") no-repeat center center; }
		.field-checkbox .checkbox-wrap .placeholder { background: url("@{imgs}checkbox.png") no-repeat center center; }
	}
	label, .label { .fl; font: 14px/@fieldHeight Arial; color: #666; width: @labelWidth; .bs;
		&.empty { min-height: 1px; }
	}
	.input-wrap { border: 1px solid @borderColor; height: @fieldHeight; padding: 2px; .fl; width: @fieldWidth; .bs;
		input { width: 100%; padding: 0; border: 0; height: 100%; .db; background: none; }
	}
	.text-wrap { border: 1px solid @borderColor; padding: 2px; .fl; width: @fieldWidth;  .bs;
		textarea { width: 100%; padding: 0; border: 0; height: 100%; resize: none; }
	}
	.select-wrap { border: 1px solid @borderColor; height: @fieldHeight; .fl; width: @fieldWidth;  .bs;
		select { width: 100%; padding: 0; border: 0; height: 100%; }
	}
	.field-checkbox {
		.checkbox-wrap { .fl; .bs; .pr; .oh; margin-top: 5px;
			&:hover { cursor: pointer; }
			input { .pa; top: -200%; left: -200%; }
			.placeholder { border: 1px solid @borderColor; .db; height: 16px; width: 16px; .bs; }
		}
		label { .db; width: 100%; padding-left: 35px; .fn;
			&:hover { cursor: pointer; }
		}
	}
	.field-radio {
		.radio-wrap { .fl; .bs; .pr; .oh; margin-top: 5px;
			&:hover { cursor: pointer; }
			input { .pa; top: -200%; left: -200%; }
			.placeholder { border: 1px solid #ccc; border-radius: 50%; .db; height: 16px; width: 16px; .bs; }
		}
		label { .db; width: 100%; padding-left: 35px; .fn;
			&:hover { cursor: pointer; }
		}
	}
	.file-wrap { border: 1px solid @borderColor; height: @fieldHeight; width: 100%; .bs; .pr; .oh;;
		input { .pa; top: -100%; left: -100%; }
		label { .pa; top: 0; left: 0; width: 100%; height: 100%; padding-left: 2px; .bs;
			.filled {  }
			&:hover { cursor: pointer; }
			.button { .pa; right: 0; top: 0; width: @labelWidth; background: @borderColor; .tac; color: white; }
		}
	}
	.info-wrap { height: @fieldHeight; padding: 2px; .fl; width: @fieldWidth; .bs;
		span { .db; width: 100%; padding: 0; border: 0; height: 100%; .db; }
	}
}

/*** Submit wrap ***/
.submit-wrap { .oh; margin-top: 10px;
	.submit { .fr; }
	.feedback { .fl; }
}

/*** Feedback ***/
.feedback { .dn;
	&.general { .db; border: 1px solid green; color: green; padding: 2px 5px; margin: 5px 0; font: 14px Arial; }
	&.error { color: red; }
}

/*** Dialogs ***/
.dialog {
	h3 { background: black; color: white; margin: 0; padding: 10px 30px 10px 10px; white-space: nowrap; }
	.dialog-content { padding: 10px; background: white;
		p { margin: 0; }
		.buttons { margin-top: 20px; border-top: 1px solid #eee; padding-top: 20px; .oh;
			a { .fr; background: black; color: white; padding: 5px 10px; }
			.action-confirm { margin-left: 10px; }
		}
	}
}