/*** Varie ***/
@imgs:		"../images/";
.fl			{ float: left; }
.fr			{ float: right; }
.fn			{ float: none; }
.pr			{ position: relative; }
.pa			{ position: absolute; }
.ps			{ position: static; }
.pf			{ position: fixed; }
.dn			{ display: none; }
.db			{ display: block; }
.dib		{ display: inline-block; }
.tac		{ text-align: center; }
.tal		{ text-align: left; }
.tar		{ text-align: right; }
.tuc		{ text-transform: uppercase; }
.tul		{ text-decoration: underline; }
.oh			{ overflow: hidden; }
.cf {
	*zoom: 1;
	&:before, &:after { content: " "; display: table; }
	&:after { clear: both; }
}
.bs(@sizing: border-box) {
	-ms-box-sizing: @sizing;
	-moz-box-sizing: @sizing;
	-webkit-box-sizing: @sizing;
	box-sizing: @sizing;
}